import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '70%'
  },
  list: {
    marginTop: theme.spacing(2),
    maxHeight: '400px',
    overflow: 'auto'
  },
  addButton: {
    marginLeft: theme.spacing(2),
  },
}));

const EquipmentTypes = (props) => {
  const classes = useStyles();
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const [newType, setNewType] = useState('');

  useEffect(() => {
    // Fetch equipment types from the backend
    axios
      .get(`${props.url}/api/settings/equipmentTypes`)
      .then((response) => {
        setEquipmentTypes(response.data);
      })
      .catch((error) => {
        props.notification({ title: 'Error', message: 'Failed to fetch equipment types', type: 'error' });
      });
  }, []);

  const handleAddType = () => {
    if (newType.trim() === '') {
      props.notification({ title: 'Error', message: 'Equipment type cannot be empty', type: 'error' });
      return;
    }

    axios
      .post(`${props.url}/api/settings/equipmentTypes`, { type: newType })
      .then((response) => {
        setEquipmentTypes([...equipmentTypes, response.data]);
        props.notification({ title: 'Success', message: 'Equipment type added successfully', type: 'success' });
        setOpen(false);
        setNewType('');
      })
      .catch((error) => {
        props.notification({ title: 'Error', message: 'Failed to add equipment type', type: 'error' });
      });
  };

  const handleDeleteType = (typeToDelete) => {
    let msg = `Are you sure you wish to delete ${typeToDelete.type}?`
    const typeId = typeToDelete._id
    if (window.confirm(msg)) {
      axios
      .delete(`${props.url}/api/settings/equipmentTypes?id=${typeId}`)
      .then(() => {
        setEquipmentTypes(equipmentTypes.filter((type) => type._id !== typeId));
        props.notification({ title: 'Success', message: 'Equipment type deleted successfully', type: 'success' });
      })
      .catch((error) => {
        props.notification({ title: 'Error', message: 'Failed to delete equipment type', type: 'error' });
      });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h6">Equipment Types</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpen(true)}
        >
          Add New Type
        </Button>
      </div>
      <List className={classes.list}>
        {equipmentTypes.map((type) => (
          <ListItem key={type._id} divider>
            <ListItemText primary={type.type} />
            <IconButton edge="end" onClick={() => handleDeleteType(type)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add New Equipment Type</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the name of the new equipment type:</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Equipment Type"
            fullWidth
            value={newType}
            onChange={(e) => setNewType(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddType} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EquipmentTypes;

import React, { useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'
import { Button, CircularProgress } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UploadsList from './UploadsList'
import ImageGallery from '../Uploads/NewPhotoGallery';

const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  const UploadsAccordion = ({
    client,
    projects,
    url,
    notification,
    handleChange,
    expanded,
    numberOfUploads,
    numberOfPhotos
  }) => {
    const [currentClientId, setCurrentClientId] = useState('')
    const [uploads, setUploads] = useState([]);
    const [images, setImages] = useState([])
    const [isLoadingFiles, setIsLoadingFiles] = useState(false);
    const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
    const [error, setError] = useState(null);
    const [filesPage, setFilesPage] = useState(1);
    const [photosPage, setPhotosPage] = useState(1);
    const [hasMoreFiles, setHasMoreFiles] = useState(true);
    const [hasMorePhotos, setHasMorePhotos] = useState(true);

    const getClientFiles = useCallback(async (pageNum = 1) => {
      if (!client?._id) return;
  
      try {
        setIsLoadingFiles(true);
        setError(null);
        
        const response = await axios.get(`${url}/api/customer/getClientUploads`, {
          params: { 
            id: client._id,
            page: pageNum,
            limit: 20,
            type: 'files'
          },
          timeout: 10000
        });
        
        if (response?.data) {
          const newUploads = response.data.uploads || [];
          
          if (pageNum > 1) {
            setUploads(prev => [...prev, ...newUploads]);
          } else {
            setUploads(newUploads);
          }
          
          setHasMoreFiles(response.data.hasMore);
        }
      } catch (err) {
        console.error('Error fetching files:', err);
        handleError(err);
      } finally {
        setIsLoadingFiles(false);
      }
    }, [client?._id, url]);
  
    const getClientPhotos = useCallback(async (pageNum = 1) => {
      if (!client?._id) return;
  
      try {
        setIsLoadingPhotos(true);
        setError(null);
        
        const response = await axios.get(`${url}/api/customer/getClientUploads`, {
          params: { 
            id: client._id,
            page: pageNum,
            limit: 20,
            type: 'photos'
          },
          timeout: 10000
        });
        
        if (response?.data) {
          const newPhotos = response.data.uploads || [];
          
          if (pageNum > 1) {
            setImages(prev => [...prev, ...newPhotos]);
          } else {
            setImages(newPhotos);
          }
          
          setHasMorePhotos(response.data.hasMore);
        }
      } catch (err) {
        console.error('Error fetching photos:', err);
        handleError(err);
      } finally {
        setIsLoadingPhotos(false);
      }
    }, [client?._id, url]);
  
    const handleError = (err) => {
      const errorMessage = err.response?.data?.message || 'Failed to load content.';
      setError(errorMessage);
      notification({
        type: 'warning',
        title: 'Loading Issue',
        message: errorMessage
      });
    };
    const handleLoadMoreFiles = useCallback(() => {
      if (!isLoadingFiles && hasMoreFiles) {
        const nextPage = filesPage + 1;
        setFilesPage(nextPage);
        getClientFiles(nextPage);
      }
    }, [filesPage, isLoadingFiles, hasMoreFiles, getClientFiles]);
  
    const handleLoadMorePhotos = useCallback(() => {
      if (!isLoadingPhotos && hasMorePhotos) {
        const nextPage = photosPage + 1;
        setPhotosPage(nextPage);
        getClientPhotos(nextPage);
      }
    }, [photosPage, isLoadingPhotos, hasMorePhotos, getClientPhotos]);
  
    useEffect(() => {
      if (client?._id !== currentClientId) {
        setCurrentClientId(client?._id);
        setUploads([]);
        setImages([]);
        setFilesPage(1);
        setPhotosPage(1);
        
        if (expanded === 'panelUploads') {
          getClientFiles(1);
        } else if (expanded === 'panelPhotos') {
          getClientPhotos(1);
        }
      } else if (expanded === 'panelUploads' && uploads.length === 0) {
        getClientFiles(1);
      } else if (expanded === 'panelPhotos' && images.length === 0) {
        getClientPhotos(1);
      }
    }, [client?._id, expanded, currentClientId]);
    

  
    const handleAccordionChange = useCallback((panel) => (event, isExpanded) => {
      handleChange(panel)(event, isExpanded);
    }, [handleChange]);

    return (
      <>
      <Accordion 
        square 
        expanded={expanded === 'panelUploads'} 
        onChange={(e, expanded) => {
          handleAccordionChange('panelUploads')(e, expanded);
          handleChange('panelUploads')(e, expanded);
        }}
      >
        <AccordionSummary 
          aria-controls="panelUploads-content" 
          expandIcon={<ExpandMoreIcon />} 
          id="panelUploads-header"
        >
          <Typography>
            Uploads (Files { numberOfUploads})
            {(isLoadingFiles) && <CircularProgress size={24} style={{ marginLeft: 8 }} />}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          
          {error ? (
            <div>
              <Typography color="error">{error}</Typography>
              <Button 
                onClick={() => getClientUploads()} 
                variant="outlined" 
                size="small"
                style={{ marginTop: 8 }}
              >
                Retry Loading
              </Button>
            </div>
          ) : (
            <UploadsList
             client={client}
              uploads={uploads}
              projects={projects}
             hasMore={hasMoreFiles}
             isLoading={isLoadingFiles}
             onLoadMore={handleLoadMoreFiles}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion 
        square 
        expanded={expanded === 'panelPhotos'} 
        onChange={(e, expanded) => {
          handleAccordionChange('panelPhotos')(e, expanded);
          handleChange('panelPhotos')(e, expanded);
        }}
      >
        <AccordionSummary 
          aria-controls="panelPhotos-content" 
          expandIcon={<ExpandMoreIcon />} 
          id="panelPhotos-header"
        >
          <Typography>
            Photos {numberOfPhotos }
            {isLoadingPhotos && <CircularProgress size={24} style={{ marginLeft: 8 }} />}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          
          {error ? (
            <div>
              <Typography color="error">{error}</Typography>
              <Button 
                onClick={() => getClientUploads()} 
                variant="outlined" 
                size="small"
                style={{ marginTop: 8 }}
              >
                Retry Loading
              </Button>
            </div>
          ) : (
            <ImageGallery 
              images={images}
              hasMore={hasMorePhotos}
              isLoading={isLoadingPhotos}
              onLoadMore={handleLoadMorePhotos}
            />
          )}
        </AccordionDetails>
      </Accordion>
      </>
    );
  };
  
  export default UploadsAccordion;

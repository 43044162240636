import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/EditRounded';
// import styles from './customer.module.scss'
import NewNoteModal  from './NewNoteModal'
import { convertFromRaw, EditorState } from 'draft-js';

const isValidDraftContent = (content) => {
  return content &&
    content.blockMap &&
    typeof content.blockMap === 'object' &&
    Object.values(content.blockMap).every(
      (block) =>
        block.key &&
        typeof block.text === 'string' &&
        Array.isArray(block.inlineStyleRanges) &&
        Array.isArray(block.entityRanges)
    );
};

const convertContentToString = (content) => {
  try {
    // Transform blockMap to blocks array
    const blocks = Object.values(content.blockMap || {}).map((block) => ({
      key: block.key,
      type: block.type || 'unstyled',
      text: block.text || '',
      depth: block.depth || 0,
      inlineStyleRanges: block.inlineStyleRanges || [],
      entityRanges: block.entityRanges || [],
      data: block.data || {},
    }));

    const rawContentState = { blocks, entityMap: content.entityMap || {} };

    // Convert raw content to ContentState
    const contentState = convertFromRaw(rawContentState);

    // Create an EditorState from the ContentState
    const editorState = EditorState.createWithContent(contentState);

    // Convert the content to plain text
    const plainText = editorState.getCurrentContent().getPlainText();

    return plainText;
  } catch (error) {
    console.error('Error converting content to string:', error);
    return '';
  }
};

// const convertContentToString = (content) => {
//   console.log('COnvert Content To String...')
//   try {
//     // Convert the raw content to ContentState
//     const contentState = convertFromRaw(content);
//     console.log('39::;')
//     // Create an EditorState from the ContentState
//     const editorState = EditorState.createWithContent(contentState);
//     console.log('Editor State...')
//     // Convert the content to plain text
//     const plainText = editorState.getCurrentContent().getPlainText();
//     console.log("Plan TEXT 44", plainText)
//     return plainText;
//   } catch (error) {
//     console.error('Error converting content to string:', error);
//     return '';
//   }
// };

// Example usage
const defaultContent = {
  blockMap: { /* your block map data */ },
  entityMap: { /* your entity map data */ },
  selectionAfter: { /* optional */ },
  selectionBefore: { /* optional */ },
};

const useStyles = makeStyles(theme => ({
  root: {

  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  updateInfo: {
    color: '#aaa',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center'
      fontSize: 12,
      textAlign: 'center'
      // width: '100%',
      // textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 12
      // textAlign: 'right'
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
      fontSize: 12,
      textAlign: 'right'
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'right',
      fontSize: 12,
      // backgroundColor: '#ccc',
      // margin: '5%'
    },
  },
  noteContainer: {
    backgroundColor: '#ddd',
    border: '1px solid #555',
    borderRadius: 5,
    margin: 2,
    marginBottom: 8,
    padding: 2
  },
  noteFooter: {
    display: 'flex',
    color: '#000',
    fontSize: '.65em',
    justifyContent: 'flex-end'
  },
  deleteButton: {
    fontSize: '.7em; !important'
  },
  editIcon: {
    fontSize: '1.5em',
    cursor: 'pointer',
    marginLeft: 5,
    "&:hover": {
      color: 'blue' 
    }
  },
  deleteIcon: {
    fontSize: '1.5em',
    cursor: 'pointer',
    marginLeft: 5,
    "&:hover": {
      color: 'red' 
    }
  }
}));

const NotesList = (props) => {
  return (
    <div>
     
     {/* <Grid container>
      <Grid item xs={12}> */}
          {props.notes.map(note => {
            // console.log('Note: ', note)
            return (
              <Paper key={note._id} style={{ fontSize: 12, backgroundColor: '#eee', border: '1px solid #ccc', margin: 5, padding: 5, width: '100%'}}>
                 <Grid container>
                   <Grid item xs={12}>
                      {note.notes}
                  </Grid>
                  <Grid item xs={6}>
                    {note.created_by}
                  </Grid>
                  <Grid item xs={6} style={{textAlign: 'right'}}>
                    {moment(note.created_at).format('MM/DD/YYYY - hh:mm a')}
                  </Grid>
                </Grid>
              </Paper>
            )
          })}
       {/* </Grid>
      </Grid> */}
    </div>
  )
}
const ProjectNotes = (props) => {
  // console.log('Project Notes  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [project, setProject] = React.useState({})
  const [notes, setNotes] = React.useState([])
  const [showNewNoteModal, setShowNewNoteModal] = React.useState(false)
  const [state, setState] = React.useState({
    status: 'New',
    projectName: ''
  })

  // const [newNoteModalOpen, setShowNewNoteModal] = React.useState(false)
  const [note, setNote] = React.useState(null)
  // const [loading, setLoading] = React.useState(false)

  const closeNoteModal = (updatedProject) => {
    setShowNewNoteModal(false)
    if (updatedProject && updatedProject._id) {
      // console.log('Note saved', note)
      props.updateProjectNotes(updatedProject)
    }
  }

  React.useEffect(() => {
  // console.log('Notes Use effect props change;', props)
    if (props.project && props.project._id) {
      setProject(props.project)
      if (props.project.projectNotes && props.project.projectNotes.length) {
        setState({...state, notes: props.project.projectNotes})
        dealWithProjectNotes(props.project.projectNotes)
      } else {
        setState({...state, notes: []})
        setNotes([])
      }
    } else {
      setState({...state, notes: []})
      setNotes([])
      setProject({})
      // setState({...state, notes: []})
      // setNotes([])
    }
    // if (props.notes && props.notes.length) {
    //   setState({...state, notes: props.notes})
    //   setNotes(props.notes)
    // } else {
    //   setState({...state, notes: []})
    //   setNotes([])
    // }
  }, [props])

// Utility function to check if content is a Draft.js content object
const isDraftContent = (content) => {
  return (
    content &&
    typeof content === 'object' &&
    (content.blocks || content.blockMap) &&
    content.entityMap
  );
};

// Utility function to handle Draft.js content conversion
const convertDraftContent = (content) => {
  try {
    // Handle both newer (blocks) and older (blockMap) formats
    let blocks;
    if (content.blocks) {
      blocks = content.blocks;
    } else if (content.blockMap) {
      blocks = Object.values(content.blockMap).map(block => ({
        key: block.key,
        type: block.type || 'unstyled',
        text: block.text || '',
        depth: block.depth || 0,
        inlineStyleRanges: block.inlineStyleRanges || [],
        entityRanges: block.entityRanges || [],
        data: block.data || {},
      }));
    } else {
      throw new Error('Invalid Draft.js content structure');
    }

    // Create the raw content state
    const rawContentState = { 
      blocks, 
      entityMap: content.entityMap || {} 
    };

    // Convert to ContentState
    const contentState = convertFromRaw(rawContentState);
    const editorState = EditorState.createWithContent(contentState);
    
    // Get blocks and join them with newlines
    const contentBlocks = contentState.getBlocksAsArray();
    const text = contentBlocks.map(block => {
      // Handle different block types
      const blockText = block.getText();
      switch (block.getType()) {
        case 'unordered-list-item':
          return `• ${blockText}`;
        case 'ordered-list-item':
          return `1. ${blockText}`; // You might want to handle actual numbering
        case 'header-one':
          return blockText.toUpperCase();
        case 'header-two':
        case 'header-three':
          return blockText;
        default:
          return blockText;
      }
    }).join('\n');

    return text;
  } catch (error) {
    console.error('Error converting Draft.js content:', error);
    return '[Error converting note content]';
  }
};

// Updated dealWithProjectNotes function
const dealWithProjectNotes = (notesToSet) => {
  // console.log('Set Notes', notesToSet)
  if (!Array.isArray(notesToSet)) {
    console.error('Expected notes array, received:', typeof notesToSet);
    return [];
  }

  const processedNotes = notesToSet.map(note => {
    try {
      // If note is just a string, return as is
      if (typeof note === 'string') {
        return note;
      }

      // Handle note object
      const content = note.content;
      
      // If content is a string, return the note as is
      if (typeof content === 'string') {
        return note;
      }

      // If content is a Draft.js content object, convert it
      if (isDraftContent(content)) {
        const plainText = convertDraftContent(content);
        return {
          ...note,
          content: plainText
        };
      } else {
        console.log('Not draft content...', note)
      }

      // If we can't determine the content type, return original note
      console.warn('Unknown note content type:', typeof content);
      return note;
    } catch (error) {
      console.error('Error processing note:', error);
      return '[Error processing note]';
    }
  });
  // console.log('Processed:', processedNotes)
  setNotes(processedNotes);
};
  // React.useEffect(() => {
  // // console.log('Notes Use effect props.notes change;', props)
  //   // if (props.project && props.project._id) {
  //   //   setProject(props.project)
  //   //   if (props.notes && props.notes.length) {
  //   //     setState({...state, notes: props.notes})
  //   //     setNotes(props.notes)
  //   //   } else {
  //   //     setState({...state, notes: []})
  //   //     setNotes([])
  //   //   }
  //   // } else {
  //   //   setState({...state, notes: []})
  //   //   setNotes([])
  //   //   setProject({})
  //   //   // setState({...state, notes: []})
  //   //   // setNotes([])
  //   // }
  //   // if (props.notes && props.notes.length) {
  //   //   setState({...state, notes: props.notes})
  //   //   setNotes(props.notes)
  //   // } else {
  //   //   setState({...state, notes: []})
  //   //   setNotes([])
  //   // }
  // }, [props.notes])

  const handleAddNote = () => {
    setNote({})
    setShowNewNoteModal(true)
  }

  const saveNote = (d) => {
  // console.log('Save Note', d)
    props.saveNote(d)
    setShowNewNoteModal(false)
    // let newNotes = notes
    // newNotes.unshift(d)
    // setNotes(newNotes)
    // setState({...state, notes: newNotes})
  }

  const handleEditNote = (note, index) => {
  // console.log('Edit thi snote', note)
  // console.log(index)
    note.editing = true
    setNote(note)
    setShowNewNoteModal(true)
  }

  const handleDelete = (noteToDelete, index) => {
    const message = `Are you sure that you wish to delete this note? This cannot be undone.`
    if (window.confirm(message)) {
      // alert('Delete', index)
      setLoading(true)
      let data = {
        projectId: props.project._id,
        note: noteToDelete,
        index: index
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/projects/deleteNote`,
        data: data,
      }).then(response => {
        // console.log('Sent Email: ', response.data)
        setLoading(false)
        if (response && response.data && response.data.error) {
          props.notification({
            type: 'warning',
            title: 'Error Deleting Note',
            message: response.data.message
          })
          return
        }
        if (response && response.data && response.data._id) {
          // console.log(response.data)
          props.notification({
            type: 'success',
            title: 'Note Deleted!',
            message: `It's a thing of the past...`
          })
          closeNoteModal(response.data)
        } else {
          props.notification({
            type: 'warning',
            title: 'Error With This Note!',
            message: 'Something Seems to Have Gone Wrong Trying To Delete It...'
          })
        }  
      }).catch(err => {
        // // console.log('Error caught when uploading pic: ', err)
        props.notification({
          type: 'warning',
          title: 'Error With This Note! Cannot Delete.',
          message: err.message
        })
      })
    }
  }

  return (
    <div className={classes.root}>
      <NewNoteModal project={props.project} note={note} open={showNewNoteModal} updateParent={saveNote} closeModal={e => setShowNewNoteModal(false)} />
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            fullWidth
            onClick={handleAddNote}
            style={{backgroundColor: 'rgb(172,202,205)', color: 'rgb(81,110,118)'}}>Add Note</Button>
        </Grid>
        <Grid item xs={12}>
          <NotesList notes={props.notes || []} />
        </Grid>
      </Grid>
      {
        notes.map((note, index) => {
          let content = null
          if (note && note.content) {
            content = note.content
          } else {
            content = note
          }
          return (
            <div key={index} className={classes.noteContainer} onDoubleClick={e => handleEditNote(note, index)}>
              <div dangerouslySetInnerHTML={{__html: content }} ></div>
              <span className={classes.noteFooter} >
                {note.created_by ? `${note.created_by} ${moment(note.created_at).format('MM/DD/YYYY [at] hh:mm a')}` : null}
                {/* <IconButton className={classes.deleteButton} edge='end' aria-label="delete" onClick={e => handleDelete(note, index)}>
                  <DeleteIcon style={{ fontSize: '1.5em' }} />
                </IconButton> */}
                <EditIcon className={classes.editIcon} onClick={e => handleEditNote(note, index)} />
                <DeleteIcon className={classes.deleteIcon} onClick={e => handleDelete(note, index)} />
              </span>
            </div>
          )
        })}
    </div>
  )
}


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(ProjectNotes)
  
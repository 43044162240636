import React, { useState, useEffect, useRef } from 'react';
import { 
  Grid,
  Card,
  CardMedia,
  ButtonGroup,
  Button,
  Typography,
  Box,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    maxHeight: '80vh',
    overflowY: 'scroll'
  },
  card: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.02)',
      transition: 'transform 0.2s ease-in-out'
    }
  },
  media: {
    paddingTop: '100%',
    backgroundSize: 'cover'
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 70%, transparent 100%)',
    color: 'white',
    padding: theme.spacing(1)
  },
  crewBadge: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    background: 'rgba(0,0,0,0.7)',
    color: 'white',
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(0.5),
    fontSize: '0.8rem',
    fontWeight: 500
  },
  dateText: {
    fontSize: '0.8rem',
    marginBottom: theme.spacing(0.5)
  },
  projectName: {
    fontSize: '0.9rem',
    fontWeight: 500
  },
  selectedImage: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1300
  },
  selectedImageContent: {
    maxHeight: '90vh',
    maxWidth: '90vw',
    objectFit: 'contain'
  },
  loadMoreContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(1)
  }
}));

const ImageGallery = ({ images, onLoadMore, hasMore, isLoading }) => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState(null);
  const observerTarget = useRef(null);
  const gridRef = useRef(null);

  // Infinite scroll observer
  useEffect(() => {
    const currentObserverTarget = observerTarget.current;
    
    if (!currentObserverTarget || isLoading) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          console.log('Intersection observed, loading more...');
          onLoadMore();
        }
      },
      {
        root: gridRef.current,
        rootMargin: '100px',
        threshold: 0.1
      }
    );

    observer.observe(currentObserverTarget);

    return () => {
      if (currentObserverTarget) {
        observer.unobserve(currentObserverTarget);
      }
    };
  }, [hasMore, isLoading, onLoadMore]);

  const formatDate = (date) => {
    if (!date) return '';
    try {
      return format(new Date(date), 'MMM d, yyyy h:mm a');
    } catch (e) {
      console.error('Date formatting error:', e);
      return '';
    }
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handleKeyDown = (e) => {
    if (!selectedImage) return;

    const currentIndex = images.findIndex(img => img._id === selectedImage._id);
    
    if (e.key === 'ArrowRight') {
      const nextImage = images[currentIndex + 1];
      if (nextImage) setSelectedImage(nextImage);
    } else if (e.key === 'ArrowLeft') {
      const prevImage = images[currentIndex - 1];
      if (prevImage) setSelectedImage(prevImage);
    } else if (e.key === 'Escape') {
      handleClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedImage, images]);

  return (
    <div className={classes.root}>
      <div className={classes.gridContainer} ref={gridRef}>
        {images.map((image) => (
          <Card 
            key={image._id} 
            className={classes.card}
            onClick={() => setSelectedImage(image)}
          >
            <CardMedia
              className={classes.media}
              image={image.url}
              title={image.filename || ''}
            />
            {image.crew?.name && (
              <div className={classes.crewBadge}>
                {image.crew.name} {`${image.crew?.foremanName}` ? `(${image.crew.foremanName})` : ''}
              </div>
            )}
            <div className={classes.overlay}>
              <Typography className={classes.dateText}>
                {formatDate(image.created_at)}
              </Typography>
              {image.project?.name && (
                <Typography className={classes.projectName}>
                  {image.project.name}
                </Typography>
              )}
            </div>
          </Card>
        ))}
        
        {/* Loading indicator and Load More button */}
        <div ref={observerTarget} className={classes.loadMoreContainer}>
          {isLoading && <CircularProgress size={24} />}
          {hasMore && !isLoading && (
            <Button
              variant="contained"
              color="primary"
              onClick={onLoadMore}
              className={classes.loadMoreButton}
            >
              Load More Photos
            </Button>
          )}
          {!hasMore && images.length > 0 && (
            <Typography variant="body2" color="textSecondary">
              No more photos to load
            </Typography>
          )}
        </div>
      </div>

      {selectedImage && (
        <div 
          className={classes.selectedImage}
          onClick={handleClose}
        >
          <img
            src={selectedImage.url}
            alt={selectedImage.filename || ''}
            className={classes.selectedImageContent}
          />
        </div>
      )}
    </div>
  );
};

export default ImageGallery;

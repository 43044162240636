import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
// import ServiceDetailList from './ServiceDetailList'
// import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, OutlinedInput }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import VariableBilling from './VariableBilling'
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import DialogContentText from '@material-ui/core/DialogContentText';
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
// import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { currencyFormat, mtcFrequencies, daysOfTheWeek, weekOfTheYear, isEven, validateEmail } from '../../../services/common'
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InvoiceLineItemsToShowUpComponent from './InvoiceLineItemsToShowUpComponent'
import DeleteComponent from '../../Common/DeleteComponent'
import SelectedMtcTasks from '../../MtcRoutes/SelectedMtcTasks'
import EditJobSiteModal from './EditJobSiteModal'
import HeadsUpStatusBar from '../../Common/CustomStatusBar'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// console.log('Test Currency format', currencyFormat(4))
const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '10px',
    // margin: '5px'
  },
  daysArea: {
    marginLeft: 5
  },
  adotNotificationRecipientsContainer: {
    border: '2px solid #eee',
    borderRadius: 5,
    padding: 10,
    margin: 5
  },
  formControl: {
    width: '100%'
  },
  statusBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    margin: 15,
    zIndex: 9999
  },
  statusBarHeader: {
    backgroundColor: 'blue'
  },
  statusBarContent: {
    backgroundColor: 'red'
  }
}));

const SiteRow = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

const SiteName = styled(Grid)`
  display: flex;
  align-self: flex-start;
  flex-grow: 1;
`;

const SiteDetails = styled(Grid)`
  display: flex;
  flex-grow: 1;
  align-self: flex-start;
  justify-content: space-evenly;
`;
const SiteDetail = styled.div`
  display: flex;
`;

const SiteAction = styled(Grid)`
  display: flex;
  align-self: flex-end;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const handleFocus = (event) => event.target.select();

const JobSiteOptionDetails = (props) => {
  console.log('Jobsite options:', props)
  const classes = useStyles()
  let jobSite = props.jobSite
  const [state, setState] = React.useState({
    frequency: '',
    preferredDay: '',
    irrigationCheckFrequency: 'Monthly',
    amount: '',
    preferredDays: [],
    amountType: '',
    crew: {},
    mowingRequirements: 'None',
    mowingTime: 0,
    estimatedHours: 0,
    crewSize: 3
  })

  React.useEffect(() => {
    if (props.jobSite && props.jobSite._id) {
      console.log(jobSite)
      let ourCrew = {}
      if (props.jobSite && props.jobSite.crew) {
        console.log('Find Index for thisOne:', props.jobSite.crew)
        let index = props.crews.findIndex(item => (item._id === props.jobSite.crew))
        console.log('index:', index)
        if (index > -1) {
          ourCrew = props.crews[index]
        }
      }
      setState({...state, frequency: jobSite.frequency, evenOrOddWeeks: jobSite.evenOrOddWeeks, irrigationCheckFrequency: jobSite.irrigationCheckFrequency, mowingRequirements: jobSite.mowingRequirements, mowingTime: jobSite.mowingTime, preferredDay: jobSite.preferredDay, amount: jobSite.amount, amountType: jobSite.amountType, crew: ourCrew })
    }
  }, [jobSite])

  const handleChange = (e, type) => {
    let newState = {...state}
    newState[type] = e.target.value
  console.log('Set State 165', newState)
    setState({...state, ...newState})
    props.updateParent(newState)
  }

  const handlepreferredDayChange = (e) => {
    let newState = { ...state, preferredDay: e.target.value }
    setState(newState)
    props.updateParent(newState)
  }

  const handlepreferredDaysChange = (e) => {
    // console.log('Preferred days', e.target.value)
    let newState = { ...state, preferredDays: e.target.value, preferredDay: e.target.value[0] }
    setState(newState)
    props.updateParent(newState)
  }

  const updateCrew = (e, newCrew) => {
    setState({...state, crew: newCrew })
  }
  let evenWeek = isEven(weekOfTheYear)
let weekType = evenWeek ? 'Even' : 'Odd'

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="frequency"
            label="Visit Frequency"
            type="text"
            fullWidth
            select
            variant="outlined"
            value={state.frequency}
            className={classes.textField}
            // onChange={ handleChange }
            onChange={ e => handleChange(e, 'frequency') }
            // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
            InputLabelProps={{
              shrink: true,
            }}
            // onBlur={updateParent}
          >
            {
            mtcFrequencies.map((item, index) => {
              return (
                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
              )
            })
          }
          </TextField>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {
              state.frequency === 'Bi-Weekly' ? (
                <FormControl className={classes.paddedStuff} fullWidth>
              <TextField
                type="select"
                select
                className={classes.textField}
                variant="outlined"
                value={state.evenOrOddWeeks}
                onChange={ e => handleChange(e, 'evenOrOddWeeks') }
                label="Week Assignment"
                // onChange={(e) => setState({...state, phone['type']: e.target.value })}
                // className={classes.paddedStuff}
                inputProps={{ 'aria-label': 'Odd or Even Weeks' }}
                fullWidth
                placeholder="Choose Which Week"
              >
                <MenuItem value={"Odd"}>
                  Odd
                </MenuItem>
                <MenuItem value={"Even"}>Even</MenuItem>
              </TextField>
              <FormHelperText>Odd Or Even Weeks of the Year (This week is {weekType})</FormHelperText>
            </FormControl>
              ) : ''
            }
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
            id="crew"
            options={props.crews || []}
            getOptionLabel={(option) => {
              return `${option.name} ${option.foremanName ? `(${option.foremanName})` : '' } `
            }}
            // style={{ width: 200 }}
            // className={classes.paddedStuff}
            // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
            onChange={updateCrew}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.crew}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Assigned Crew" />}
          />
        </Grid>
      
      <Grid item lg={12} md={12} sm={12} xs={12}>
          {
            state.frequency === 'Twice a Week' ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="preferredDay-label">Preferred Days</InputLabel>
                <Select
                  labelId="preferredDay-label"
                  id="preferredDay"
                  multiple
                  fullWidth
                  value={state.preferredDays} // This should be an array
                  onChange={handlepreferredDaysChange}
                  input={<OutlinedInput label="Preferred Days" />}
                  renderValue={(selected) => selected.join(', ')} // Display selected items
                >
                  {daysOfTheWeek.map((item, index) => (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : state.frequency === 'Every Other Day (M-W-F)' ? (
              <TextField
                  id="preferredDay"
                  label="Preferred Day"
                  type="text"
                  fullWidth
                  variant="outlined"                  
                  value={'Monday Wednesday Friday'}
                  className={classes.textField}
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  // onBlur={updateParent}
                />
            ) : (
              <TextField
                id="preferredDay"
                label="Preferred Day"
                type="text"
                fullWidth
                variant="outlined"
                select
                value={state.preferredDay}
                className={classes.textField}
                onChange={ handlepreferredDayChange }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                // onBlur={updateParent}
              >
              {
                daysOfTheWeek.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                  )
                })
              }
              </TextField>
            )
          }

        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="amountType"
            label="Amount Type"
            fullWidth
            // className={classes.paddedStuff}
            // type="number"
            select
            defaultValue={false}
            variant="outlined"
            value= {state.amountType}
            className={classes.textField}
            helperText="Is this a Monthly Biller or a Per Visit?"
            onChange={ e => handleChange(e, 'amountType') }
            // onChange={ handleChange }
            >
              <MenuItem key="monthly" value={'monthly'}>Monthly</MenuItem>
              <MenuItem key="perVisit" value={'perVisit'}>Per Visit</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="amount"
            label="Amount"
            type="number"
            fullWidth
            variant="outlined"
            value={state.amount}
            className={classes.textField}
            onChange={ e => handleChange(e, 'amount') }
            // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
            InputLabelProps={{
              shrink: true,
            }}
            // onBlur={updateParent}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              id="crewSize"
              label="Size Of Crew"
              fullWidth
              // className={classes.paddedStuff}
              type="number"
              onFocus={handleFocus}
              defaultValue={0}
              variant="outlined"
              value= {state.crewSize}
              className={classes.textField}
              helperText="How Large Is the Crew?"
              onChange={ e => handleChange(e, 'crewSize') }
              // onChange={ handleChange }
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              id="estimatedHours"
              label="Hours Required"
              fullWidth
              // className={classes.paddedStuff}
              // type="number"
              type="number"
              defaultValue={0}
              variant="outlined"
              onFocus={handleFocus}
              value= {state.estimatedHours}
              className={classes.textField}
              helperText="How Many Hours Per Visit?"
              onChange={ e => handleChange(e, 'estimatedHours') }
              // onChange={ handleChange }
              />
          </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="irrigationCheck"
            label="Irrigation Check Frequency"
            fullWidth
            // className={classes.paddedStuff}
            // type="number"
            select
            defaultValue={false}
            variant="outlined"
            value= {state.irrigationCheckFrequency}
            className={classes.textField}
            helperText="How Often Will We Do Irrigation Checks?"
            onChange={ e => handleChange(e, 'irrigationCheckFrequency') }
            // onChange={ handleChange }
            >
               <MenuItem key="none" value={'None'}>None</MenuItem>
              <MenuItem key="monthly" value={'Monthly'}>Monthly</MenuItem>
              <MenuItem key="quarterly" value={'Quarterly'}>Quarterly</MenuItem>
              <MenuItem key="annually" value={'Annually'}>Annually</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              id="mowingRequirements"
              label="Mowing Requirements"
              fullWidth
              // className={classes.paddedStuff}
              // type="number"
              select
              defaultValue={false}
              variant="outlined"
              value= {state.mowingRequirements}
              className={classes.textField}
              helperText="What are the Mowing Requirements?"
              onChange={ e => handleChange(e, 'mowingRequirements') }
              // onChange={ handleChange }
              >
                <MenuItem key="none" value={'None'}>None</MenuItem>
                <MenuItem key="onDemand" value={'On Demand'}>On Demand</MenuItem>
                <MenuItem key="weekly" value={'Weekly'}>Weekly</MenuItem>
              </TextField>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              id="mowingTime"
              label="Mowing Time"
              fullWidth
              // className={classes.paddedStuff}
              // type="number"
              type="number"
              onFocus={handleFocus}
              disabled={!state.mowingRequirements}
              defaultValue={0}
              variant="outlined"
              value= {state.mowingTime}
              className={classes.textField}
              helperText="How Many Hours Per Mow?"
              onChange={ e => handleChange(e, 'mowingTime') }
              // onChange={ handleChange }
              />
          </Grid>
      </Grid>
    </div>
  )
}

const AddJobsiteModal = (props) => {
  const defaultState = {
    jobSite: null,
    frequency: '',
    preferredDay: '',
    preferredDays: [],
    amount: 0,
    amountType: '',
    irrigationCheckFrequency: 'Monthly',
    mowingRequirements: 'None',
    mowingTime: 0,
  }
// console.log('Add Jobsite Modal', props)
  const [state, setState] = React.useState(defaultState)

  const handleClose = () => {
    props.closeAddJobsiteModal()
  }
  const updateJobSite = (e, newSite) => {
    console.log('Add jobsite:', newSite)
    setState({...state, jobSite: newSite, title: '', message: '' })
  }

  const handleSaveJobsite = () => {
    // let site = state.jobSite
    // site.freq
    // let siteOptions = state
  console.log('Save Jobsite:', state)
    props.updateParent(state)
    setState(defaultState)
  }

  const updateJobSiteOptions = (data) => {
  console.log('Update our jobsiteOptions 307', data)
    setState({...state, ...data})
  }

  // const handleSaveJobsite = () => {
  //   // let site = state.jobSite
  //   // site.freq
  //   // let siteOptions = state
  // console.log('Save Jobsite:', state)
  //   let site = {...props.activeSite}
  //   let newState = {...state}
  //   newState.jobSite = site
  //   props.updateParent(newState)
  //   setState(defaultState)
  // }

  // const updateJobSiteOptions = (data) => {
  // console.log('Update our jobsiteOptions', data)
  //   setState({...state, ...data})
  // }

  return (
    <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
      <DialogTitle>Add Jobsite</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <DialogContentText>Choose the Jobsite to Add to this Maintenance Agreement</DialogContentText>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {
              (props.jobSites && props.jobSites.length) ? (
                <Autocomplete
                  id="jobsite-picker"
                  fullWidth
                  options={props.jobSites}
                  value={state.jobSite}
                  onChange={updateJobSite}
                  getOptionLabel={(option) => `${option.name} - ${option.address} ${option.buildingNumber ? `(${option.buildingNumber})` : ''}` }
                  renderInput={(params) => <TextField {...params} label="Job Site" variant="outlined" />}
                />
              ) : 'All Customer Jobsites Included Already'
            }
           
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {
              (state.jobSite && state.jobSite._id) ? (
                <JobSiteOptionDetails crews={props.crewList} jobSite={state.jobSite} updateParent={updateJobSiteOptions} />
              ) : null
            }
          </Grid>
        </Grid>
        
        

      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSaveJobsite}
          color="primary"
          disabled={!state.jobSite || !state.frequency || !state.preferredDay || !state.amount || !state.amountType}
        >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

const JobsiteList = (props) => {
  const classes= useStyles()
  let sites = props.includedSites
// console.log('Jobsite list:', sites)
  const handleDelete = (site) => {
    props.removeSite(site)
  }

  const editItem = (site) => {
    props.editSite(site)
  }
  // console.log('530 site', sites)
  return (
    <div style={{backgroundColor: '#fff'}}>
      {
        sites.map((site, index) => {
        // console.log('SITE 459:', site)
          return (
            <SiteRow key={index} style={{ border: '1px solid #ccc', borderRadius: 5, margin: 10, padding: 5 }}>
              <SiteName item lg={2} md={2} sm={4} xs={12}> {site.communityName ? `${site.communityName} - ` : ''} {(site && site.name) ? site.name : ''} {site.address ? site.address : '' } {site.buildingNumber ? site.buildingNumber : '' } </SiteName>
              <SiteDetails item lg={2} md={2} sm={4} xs={12}>
                {site.crewName ? site.crewName : ''}
              </SiteDetails>
              <SiteDetails item lg={6} md={5} sm={4} xs={12}>
                <SiteDetail>
                  {(site && site.frequency) ? site.frequency : ''}
                </SiteDetail>
                <SiteDetail>
                  {
                    site.frequency === 'Twice a Week' ? (
                    
                        
                          (site && site.preferredDays && site.preferredDays.length > 0) ? (
                        
                              
                                site?.preferredDays.map((day, index) => {
                                  return (
                                    <div key={index} className={classes.daysArea}>{day}</div>
                                  )
                                })
                              
                          ) : 'None'
                        
                    ) : site.frequency === 'Every Other Day (M-W-F)' ? 'Monday Wednesday Friday' : (site && site.preferredDay) ? site.preferredDay : ''
                  }
                </SiteDetail>
                <SiteDetail>
                {site.amountType} {(site && site.amount) ? `${currencyFormat(site.amount)}`: ''}
                </SiteDetail>
              </SiteDetails>
              <SiteAction item lg={2} md={3} sm={4} xs={6}>
                <span style={{marginLeft: 'auto'}}>
                  <Tooltip title="Edit">
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={e => editItem(site)}
                      >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" style={{ marginLeft: '5em' }}>
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={e => handleDelete(site)}
                      >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              </SiteAction>
            </SiteRow>
          )
        })
      }
    </div>
  )
}

const JobSitesContainer = (props) => {
console.log('Jobsites Container:', props)
  const [showAddJobsiteModal, setShowJobsiteModal] = React.useState(false)
  const [showEditJobsiteModal, setShowEditJobsiteModal] = React.useState(false)
  const [activeSite, setActiveSite] = React.useState({})
  const handleToggleJobSiteModal = () => {
  // console.log('Toggle Jobsite modal!!!')
    setShowJobsiteModal(!showAddJobsiteModal)
  }

  const handleToggleEditJobSiteModal = () => {
    setShowEditJobsiteModal(false)
  }

  const updateJobSite = (data) => {
  // console.log('Update Job site 367', data)
    setShowJobsiteModal(false)
    let theJobSite = {...data.jobSite}
    const crew = data.crew
    if (crew && crew._id) {
      theJobSite.crew = crew._id
      theJobSite.crewName = crew.name
    }
    theJobSite.frequency = data.frequency
    theJobSite.amount = data.amount
    theJobSite.preferredDay = data.preferredDay
    theJobSite.preferredDays = data.preferredDays
    theJobSite.amountType = data.amountType
    theJobSite.mowingRequirements = data.mowingRequirements
    theJobSite.mowingTime = data.mowingTime
    theJobSite.estimatedHours = data.estimatedHours
    theJobSite.crewSize = data.crewSize
    theJobSite.evenOrOddWeeks = data.evenOrOddWeeks
    // console.log('THE Jobsite:', theJobSite)
    props.updateJobsiteList(theJobSite)
  }

  const updateJobSiteAfterEdit = (data) => {
  // console.log('Update this site after edit', data)
    setShowEditJobsiteModal(false)
    let theJobSite = {...data.jobSite}
    const crew = data.crew
    if (crew && crew._id) {
      theJobSite.crew = crew._id
      theJobSite.crewName = crew.name
    }
    theJobSite.frequency = data.frequency
    theJobSite.amount = data.amount
    theJobSite.preferredDay = data.preferredDay
    theJobSite.preferredDays = data.preferredDays
    theJobSite.amountType = data.amountType
    theJobSite.irrigationCheckFrequency = data.irrigationCheckFrequency
    theJobSite.mowingRequirements = data.mowingRequirements
    theJobSite.mowingTime = data.mowingTime
    theJobSite.estimatedHours = data.estimatedHours
    theJobSite.crewSize = data.crewSize
    theJobSite.evenOrOddWeeks = data.evenOrOddWeeks
    // console.log('THE Jobsite:', theJobSite)
    props.updateJobsiteList(theJobSite, true)
  }
  const handleRemoveJobsite = site => {
  // console.log('Remvoe site:: 285', site)
    props.removeJobSite(site)
  }
  
  const handleEditSite = site => {
  // console.log('Edit site', site)
    setActiveSite(site)
    setShowEditJobsiteModal(true)
  }
  return (
    <div style={{ marginTop: '1rem', marginBottom: '1rem', backgroundColor: 'rgba(23, 2, 4, 0.1)', borderRadius: 7, padding: '1rem' }}>
      <AddJobsiteModal open={showAddJobsiteModal} {...props} closeAddJobsiteModal={handleToggleJobSiteModal} updateParent={updateJobSite} />
      <EditJobSiteModal open={showEditJobsiteModal} {...props} activeSite={activeSite} closeAddJobsiteModal={handleToggleEditJobSiteModal} updateParent={updateJobSiteAfterEdit} />
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={6} xs={6} style={{ color: '#777', fontSize: '1.24em'}}>
          Jobsites Included {props.includedSites.length}
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Button
            size="small"
            style={{marginLeft: 'auto'}}
            variant="contained"
            color="primary"
            onClick={handleToggleJobSiteModal}
          >Add Jobsite</Button>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <JobsiteList includedSites={props.includedSites} removeSite={handleRemoveJobsite} editSite={handleEditSite} />
        </Grid>
      </Grid>
    </div>
  )
}
// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }

const servicePlans = [
  {
    name: 'Basic Service Plan',
    num: 1,
    value: 'BSP'
  },
  {
    name: 'Advantage Service Plan',
    num: 2,
    value: 'ASP'
  },
  {
    name: 'Benhart Hourly Plan',
    num: 3,
    value: 'BHP'
  },
  {
    name: 'Flat Rate Plan (Benhart)',
    num: 4,
    value: 'FRP'
  },
  // {
  //   name: 'Super Service Plan',
  //   num: 3,
  //   value: 'SSP'
  // }
]

const frequencyOptions = [
  {
    name: 'Weekly',
    num: 1,
    value: 'weekly'
  },
  {
    name: 'Bi-Weekly',
    num: 2,
    value: 'biweekly'
  },
  {
    name: 'Monthly',
    num: 3,
    value: 'monthly'
  },
  {
    name: 'Quarterly',
    num: 4,
    value: 'quarterly'
  }
]
const defaultStateValues = {
  notes: '',
  daysOfTheWeek: daysOfTheWeek,
  frequencyOptions: frequencyOptions,
  preferredDay: {name: 'No Preference', num: 0},
  jobSiteAddress: {},
  jobName: '',
  jobSites: [],
  includedSites: [],
  monthlyPrice: 270,
  estMinutes: 3,
  jobSite: {},
  startDate: new Date(),
  variableBillingOptions: [ 'No', 'Yes' ],
  variableBilling: false,
  firstMonthPrice: 0,
  savedVariableBilling: false,
  waterFeatureSchedule: 0,
  irrigationCheckSchedule: 0,
  snoutWeevil: false,
  fertilizations: false,
  lightinCheckSchedule: 0,
  assignedCrew: { name: 'None' },
  crewList: [{ name: 'None' }],
  frequency: { name: 'None' },
  contractNumber: '',
  poNumber: '',
  woNumber: '',
  billingFrequency: '',
  customerType: 'Commercial',
  onSiteChecklist: false,
  showHoursWorked: false,
  singularBilling: false,
  ignoreOnRoutes: false,
  doNotSentHerbicideInvoices: false,
  doNotSendHerbicideNotifications: false,
  combineDumpInvoices: false,
  poNumberRequired: false,
  expirationDate: '',
  treeWorkerRate: '',
  operatorRate: '',
  laborRate: '',
  sprayTechRate: '',
  supervisorRate: '',
  irrigationTechRate: '',
  arboristRate: '',
  autoRenew: false,
  invoiceLineItems: [],
  isActive: false,
  variablePricing: [],
  fertilizationSchedule: 0,
  fertilizationFee: 0,
  spraySchedule: '',
  sprayFee: 0,
  overseedingSchedule: '',
  overseedingFee: 0,
  isNorthernPkwy: false,
  requiresCalendar: false,
  requiresSupportingDocs: false,
  title: '',
  message: '',
  statusColor: '#ccc'
}

const NewMtcAgreement = (props) => {
// console.log('New Mtc Agreement: ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open)
  const [loading, setLoading] = React.useState(false)
  const [jobName, setJobName] = React.useState('')
  const [state, setState] = React.useState(defaultStateValues)
  const [selectedTasks, setSelectedTasks] = React.useState([])
  const [list, setList] = React.useState([])
  const [recipientList, setRecipientList] = React.useState([])

  React.useEffect(() => {
    let isMounted = false

    const fetchDefaultList = async () => {
      const response = await axios.get(`${props.url}/api/settings/getDefaultMtcChecklistItems`)
      // console.log('Response', response.data)
      setList(response.data)
    }
    fetchDefaultList()
    return () => {
      isMounted = false
    }
  }, [])
  

  React.useEffect(() => {
    // console.log('Use Effect HMI MTC Modal',  props)
    // let jobName = 'Job - '
    let newState = { crewList: props.crewList, jobSites: props.jobSites, jobSiteAddress: props.jobSites[0], jobSite: props.jobSites[0] }
    // if (props.client.lastName) jobName = props.client.lastName + '- '
    // if (props.client.companyName) jobName = props.client.companyName + '- '
    if (props.contract) {
    // console.log('We have an active contract!', props.contract)
    // console.log('New State so far:', newState)
      let updatedState = Object.assign(newState, props.contract)
    // console.log('Updated State:', updatedState)
      newState = updatedState
      // Deal w/ Notification Recipients
      if (props.contract?.notificationRecipients?.length) {
        let recipients = []
        // console.log('Deal w/ these fukcers!!', props.contract?.notificationRecipients)
        props.contract.notificationRecipients.forEach(item => {
          let contact = props?.contacts?.find(contact => (contact._id === item))
          if (contact && contact._id) recipients.push(contact)
        })
        // console.log('Here they are ', recipients)
        setRecipientList(recipients)
      }
      if (props.contract && props.contract.expirationDate) {
      // console.log('Deal w/ expiration Date:', props.contract.expirationDate)
        // const tomorrow = new Date(props.contract.expirationDate.getTime() - 0 + (24 * 60 * 60 * 1000)).toISOString().slice(0, -8)
        // // moment(state.expDate).format('YYYY-MM-DD').toLocale 
        let expDate = moment(props.contract.expirationDate).format('MM/DD/YYYY') // moment(props.contract.expirationDate).format('YYYY-MM-DD').toLocale()
      // console.log('Expiration DATE::', expDate)
        newState.expirationDate = props.contract.expirationDate
        if (props.contract && props.contract.startDate) {
          newState.startDate = props.contract.startDate
        }
      }
      if (props.contract && props.contract.jobSites && props.contract.jobSites.length) {
        newState.includedSites = props.contract.jobSites
        let sitesLeftOver = props.jobSites.filter((site) => {
          let siteIndex = props.contract.jobSites.findIndex(item => (item._id === site._id))
          if (siteIndex > -1) {
            return false
          } else {
            return true
          }
        })
        newState.jobSites =  sitesLeftOver
        newState.title = ''
        newState.message = ''
      } else {
        console.log('1031 - no jobsites on this ag')
        newState.jobSites = props.jobSites
        newState.title = 'Jobsite(s) Required'
        newState.message = 'Please add a Jobsite in order to Activate this maintenance agreement.'
      }

      if (props.contract && props.contract.invoiceLineItems) {
        newState.invoiceLineItems = props.contract.invoiceLineItems
      }
      if (props.contract && props.contract.fertilizationSchedule && props.contract.fertilizationSchedule > -1) newState.fertilizationSchedule = props.contract.fertilizationSchedule
      if (props.contract && props.contract.spraySchedule) newState.spraySchedule = props.contract.spraySchedule
      if (props.contract && props.contract.requiresCalendar) newState.requiresCalendar = props.contract.requiresCalendar
      // doNotSentHerbicideInvoices: false,
      // doNotSendHerbicideNotifications: false,
      // ignoreOnRoutes
      if (props.contract && props.contract.requiresSupportingDocs) newState.requiresSupportingDocs = props.contract.requiresSupportingDocs
      if (props.contract && props.contract.startDate) newState.startDate = props.contract.startDate
      if (props.contract && props.contract.selectedTasks && props.contract.selectedTasks.length) {
        console.log('Set Selected tasks...', props.contract)
        setSelectedTasks(props.contract.selectedTasks)
        if (list && list.length) {
          console.log('LIST:::', list)
          // Add our One Off items to the default list
        } else {
          console.log('No LIst....')
        }
        let newList = [...list]
        props.contract.selectedTasks.map(task => {
          let index = newList.findIndex(listitem => (listitem._id.toString() === task._id.toString()))
          if (index === -1) newList.push(task)
        })
        console.log('Update our list to this:', newList)
        setList(newList)
      }
      // if (props.contract && props.contract.overseedingSchedule) newState.overseedingSchedule = props.contract.overseedingSchedule
    } else {
      console.log('Clear it all')
      newState = {}
      newState.title = 'Jobsite(s) Required'
      newState.message = 'Please add a Jobsite in order to Activate this maintenance agreement.'
      setState({...defaultStateValues, ...newState})
    }
    // setOpen(props.open)
  // console.log('Set the state 727:', newState)
    setState({...state, ...newState})
  }, [props])

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString('en-US'); // Adjust the locale as per your requirement
  };


const handleClose = () => {
  // console.log('Closing New Note Modal')
  // setOpen(false)
  // setState({
  //   note: ''
  // })
  setOpen(false)
  // handleToggleModal()
  props.updateParent('Close')
  setState(defaultStateValues)
}

const handleConfirm = (data) => {
  // console.log('CONFIRMED NEW JOB!!', data)
}
const handleSave = () => {
  setLoading(true)
console.log('handle save', state)
  let details = { ...state }
  // if (details.includedSites && details.includedSites.length) {
  //   let cleanSites = details.includedSites.map(site => {
  //     let newSite = {
  //       ...site,
  //       // frequency: site.frequency,
  //       jobSiteId: site._id,
  //     }
  //     return newSite
  //   })
  //   details.includedSites = cleanSites
  // }
  delete details.jobSites
  delete details.crewList
  details.client = null
  if (props.client && props.client_id) details.client = props.client_id
  details.recipientList = recipientList || []
  // console.log(props)
  let obj = {
    client: props.client,
    details: details,
    selectedTasks: selectedTasks
  }
console.log('Obj to save new mtc agreement', obj)
  axios({
    method: 'post',
    url:  `${props.url}/api/hmi/createNewMtcAgreement`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to create new Note: ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response.data._id) {
      setState(defaultStateValues)
      setOpen(false)
      props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'New Maintenance Agreement was created and saved!',
        message: 'Well Done!!'
      })
      // setOpen(false)
    } else {
      // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up creating this workorder...not sure why!'
      })
    }
    
  }).catch(err => {
    // console.log('Error saving workorder: ', err)
  })
}

const handleUpdate = () => {
console.log('UPdate this fucker!!')
  let details = { ...state }
  if (details.includedSites && details.includedSites.length) {
    let siteIds = details.includedSites.map(site => site._id)
  console.log('Site IDs', siteIds)
  }
  delete details.jobSites
  delete details.crewList
  delete details.daysOfTheWeek
  delete details.frequencyOptions
  delete details.variableBillingOptions
  details.client = null
  // details._id = props.contract._id
  if (props.client && props.client_id) details.client = props.client_id
  details.recipientList = recipientList || []
  // console.log(props)
  console.log('Details:', details)
  let obj = {
    client: props.client,
    details: details,
    agreement: props.contract,
    selectedTasks: selectedTasks
  }
console.log('Obj', obj)
  axios({
    method: 'post',
    url:  `${props.url}/api/hmi/updateMtcAgreement`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to create new Note: ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response.data._id) {
      setState(defaultStateValues)
      setOpen(false)
      props.updateParent('Updated', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'Maintenance Agreement was updated and saved!',
        message: 'Well Done!!'
      })
      // setOpen(false)
    } else {
      // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up updating this agreement...not sure why!'
      })
    }
    
  }).catch(err => {
    // console.log('Error saving workorder: ', err)
  })
}
const formatOption = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.address) {
    return `${opt.name} - ${opt.address} ${opt.city}`
  } else {
    return ''
  }
}
const updateAutoComplete = (id, value) => {
  // console.log('Update our autocomplete: ', id)
  let split = id.split('-')
  // console.log('Split: ', split)
  let optNum = split[2]
  let optItem = split[0]
  // console.log('Update: ', optItem)
  // console.log('To number: ', optNum)
  let newItem = null
  if (optItem === 'preferredDay') {
  newItem = state.daysOfTheWeek[optNum]
  setState({...state, preferredDay: newItem})
  }
  if (optItem === 'jobSite') {
    newItem = state.jobSites[optNum]
    // console.log('New job site: ', newItem)
    setState({...state, jobSite: newItem})
  }
  if (optItem === 'servicePlan') {
    newItem = state.servicePlans[optNum]
    setState({...state, servicePlan: newItem})
  }
  if (optItem === 'frequency') {
    newItem = state.frequencyOptions[optNum]
    setState({...state, frequency: newItem})
  }
  if (optItem === 'variableBilling') {
    newItem = state.variableBillingOptions[optNum]
    setState({...state, variableBilling: newItem})
  }
  if (optItem === 'assignedCrew') {
    newItem = state.crewList[optNum]
    setState({...state, assignedCrew: newItem})
  }
  // state[optItem] = newItem
  // console.log('Item to update: to: ', newItem)
}

const handleLineItems = (data) => {
  // let existingLineItems = state.invoiceLineItems || []
  // existingLineItems.push(data)
  setState({...state, invoiceLineItems: data })
}
const updateMonthlyPrice = (id, value) => {
  // console.log('Update our Monthly Price', id)
  // console.log(value)
  setState({...state, monthlyPrice: value})
  // ,
  //   janPrice: value,
  //   febPrice: value,
  //   marchPrice: value,
  //   aprilPrice: value,
  //   mayPrice: value,
  //   junePrice: value,
  //   julyPrice: value,
  //   augPrice: value,
  //   septPrice: value,
  //   octPrice: value,
  //   novPrice: value,
  //   decPrice: value
  // })
}

const updateJobSiteAddress = (e, addy) => {
  // console.log(e)
  // console.log('addy: ', addy)
  setState({...state, jobSite: addy, jobSiteAddress: addy})
}

const saveVariableBilling = (data) => {
// console.log('Save our variable billing!!', data)
  setState({...state, savedVariableBilling: true, variablePricing: data })
}

const handleToggleModal = () => {
  // setOpen(true)
  props.toggleModal(!props.open)
}

const handleChange = (e) => {
// console.log('Change: %s - %s ', e.target.id, e.target.value)
  setState({...state, [e.target.id]: e.target.value })
}

const isNotIncludedInNewSites = (value) => {
// console.log('Is included in new sites??', value)
}

const updateJobsites = (data, isEdit) => {
console.log('Update jobsites:::717', data)
console.log('Included sites!', state.includedSites)
  let newSites = [...state.includedSites]
  if (isEdit) {
  // console.log('We gotta find this one and replace i t', data)
    let siteIndex = newSites.findIndex(item => (item._id === data._id))
    if (siteIndex > -1) {
      newSites[siteIndex] = data
    }
  } else {
    newSites.push({
      ...data,
      unrouted: true
  })
  // console.log('New Sites:', newSites)
  }
  // Filter out the sites already included
  let sitesLeftOver = props.jobSites.filter((site) => {
    let siteIndex = newSites.findIndex(item => (item._id === site._id))
    if (siteIndex > -1) {
      return false
    } else {
      return true
    }
  })
  console.log('Sites Left over:', sitesLeftOver)
  console.log('Jobsites:', newSites)
  let newState = {}
  if (newSites?.length){
    newState.title = ''
    newState.message = ''
  } else {
    newState.title = 'Jobsites Required'
    newState.isActive = false
    newState.message = 'At least one jobsite is required in order to have an active maintenance agreement, please add below.'
  }
  setState(prevState => ({...prevState, includedSites: newSites, jobSites: sitesLeftOver, ...newState }))
}

const handleRemoveJobSite = (site) => {
// console.log('Remove this site', site)
// console.log(state)
  let newState = {}
  let sitesThatAreIncluded = [...state.includedSites]
// console.log('Sites Left Over:', sitesThatAreIncluded)
// console.log('All Jobsites:', props.jobSites)
  let siteIndex = sitesThatAreIncluded.findIndex(item => (item._id === site._id))
  if (siteIndex > -1) {
    sitesThatAreIncluded.splice(siteIndex, 1)
    // let newJobSites = allJobSites.push(site)
    // console.log('New Job sites:', newJobSites)
    // setState({...setState, jobSitesIncluded: currentSites, jobSites: newJobSites })
  }
  // Get our left over sites
  let sitesLeftOver = props.jobSites.filter((site) => {
    let siteIndex = sitesThatAreIncluded.findIndex(item => (item._id === site._id))
    if (siteIndex > -1) {
      return false
    } else {
      return true
    }
  })
// console.log('Sites Left Over:', sitesLeftOver)
// console.log('Sites Incldued:', sitesThatAreIncluded)
  if (sitesThatAreIncluded?.length) {
    newState.title = ''
    newState.message = ''
  } else {
    newState.title = 'Jobsites Required'
    newState.isActive = false
    newState.message = 'At least one jobsite is required in order to have an active maintenance agreement, please add below.'
  }
  setState({...state, includedSites: sitesThatAreIncluded, jobSites: sitesLeftOver, ...newState })
}

const handleDelete = (deletedItem) => {
  setState(defaultStateValues)
  props.deleteItem(deletedItem)
}

const handleTaskSelections = (updatedSelections) => {
  console.log('Selected:', updatedSelections)
  setSelectedTasks(updatedSelections)

}

const handleAddNewTaskItem = (newItem) => {
  console.log('Add this', newItem)
  let newList = [...list, newItem]
  console.log('New list', newList)
  setList(newList)
  setSelectedTasks([...selectedTasks, newItem])
}

const isInvalid = ((!state.includedSites || state.includedSites.length < 1) || !state.billingFrequency) ? true : false
// console.log('Is Invalid:', isInvalid)
// console.log('1234 ', props)
// console.log('State', state)
return (
  <div className={classes.root}>
    <Button style={{ display: 'flex', alignSelf: 'flex-end', marginLeft: 'auto' }} color="secondary" variant="contained" onClick={handleToggleModal}>New Mtc Contract</Button>
    <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth='lg' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          { (props.contract && props.contract._id) ? 'Edit' : 'Create New' } HMI Maintenance Agreement</DialogTitle>
          <HeadsUpStatusBar title={state.title} message={state.message} color={state.statusColor} />
        <DialogContent >
        <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
          <CloseIcon />
        </IconButton>
        {/* <Tooltip title="Delete MTC Contract" > */}
          <DeleteComponent
            type="Jobs"
            item={state}
            title="Delete MTC Agreement?"
            verificationText={`Are you sure you wish to Delete this agreement?`}
            confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
            confirmationButton="DELETE"
            denyText="NO"
            ifApproved={handleDelete}
            deleteFunction={'hmiMtcAgreement'}
          />
        {/* </Tooltip> */}

          <DialogContentText>
            Please fill out the information below. After saving, the system will automatically generate a new Maintenance Job for this customer.
          </DialogContentText>
          <Grid container>
          <Grid item lg={4} md={4} sm={6} xs={12} className={classes.gridItem}>
              <TextField
                id="jobName"
                label="Job Name"
                type="text"
                fullWidth
                value={state.jobName}
                className={classes.textField}
                variant="standard"
                onChange={ handleChange }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12} className={classes.gridItem}>
              <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  fullWidth
                  value={moment(state.startDate).add(7, 'hours').format('YYYY-MM-DD')}
                  // value={moment(state.expirationDate).format('YYYY-MM-DD').toLocale } // "2017-05-24"}
                  className={classes.textField}
                  variant="standard"
                  onChange={ e => setState({...state, startDate: e.target.value }) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText="When Should Billing Start?"
                  // InputProps={{
                  //   value: formatDate(state.expirationDate),
                  // }}
                />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} className={classes.gridItem}>
              <TextField
                  id="expDate"
                  label="Expiration Date"
                  type="date"
                  fullWidth
                  value={moment(state.expirationDate).add(7, 'hours').format('YYYY-MM-DD')}
                  // value={moment(state.expirationDate).format('YYYY-MM-DD').toLocale } // "2017-05-24"}
                  className={classes.textField}
                  variant="standard"
                  onChange={ e => setState({...state, expirationDate: e.target.value }) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // InputProps={{
                  //   value: formatDate(state.expirationDate),
                  // }}
                />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} className={classes.gridItem}>
            <FormControlLabel
                style={{marginLeft: 20}}
                control={<Checkbox checked={state.autoRenew} onChange={ e => setState({...state, autoRenew: !state.autoRenew } ) } name="autoRenew" />}
                label="Auto Renews"
              />
              <FormControlLabel
                style={{marginLeft: 20}}
                disabled={isInvalid}
                control={<Checkbox checked={state.isActive} onChange={ e => setState({...state, isActive: !state.isActive } ) } name="isActive" />}
                label={'Active'}
              />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} className={classes.gridItem}>
            <TextField
              id="contractNumber"
              label="Contract Number"
              type="text"
              fullWidth
              value={state.contractNumber}
              className={classes.textField}
              variant="standard"
              onChange={ handleChange }
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} className={classes.gridItem}>
              <TextField
                id="poNumber"
                label="PO Number"
                type="text"
                fullWidth
                value={state.poNumber}
                className={classes.textField}
                variant="standard"
                onChange={ handleChange }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} className={classes.gridItem}>
              <TextField
                id="woNumber"
                label="Workorder Number"
                type="text"
                fullWidth
                value={state.woNumber}
                className={classes.textField}
                variant="standard"
                onChange={ handleChange }
                // onChange={ e => handleChange(e, 'woNumber') }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
           
           
            <Grid item lg={6} md={6} sm={6} xs={12} className={classes.gridItem}>
              <TextField
                id="customerType"
                label="Customer Type"
                fullWidth
                // className={classes.paddedStuff}
                // type="number"
                select
                defaultValue="no"
                variant="standard"
                value= {state.customerType}
                helperText="Type of Customer (Defines End of Month Process)"
                onChange={ e => setState({...state, customerType: e.target.value } ) }
                // onChange={ handleChange }
                >
                   <MenuItem key="res" value="Residential">Residential</MenuItem>
                  <MenuItem key="com" value="Commercial">Commercial</MenuItem>
                  <MenuItem key="cit" value="City">City</MenuItem>
                  <MenuItem key="co" value="County">County</MenuItem>
                  <MenuItem key="st" value="State">State</MenuItem>
                  <MenuItem key="st" value="ADOT">ADOT</MenuItem>
                </TextField>
            </Grid>
            {
              state.customerType === 'ADOT' ? (
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                  <div className={classes.adotNotificationRecipientsContainer}>
                    <LabelText>Choose/Enter The Name of the Notification Receipient(s):</LabelText>
                    Crews will submit their Daily Work Report through our Mobile Application, who receives those notifications is determined by the names in the box below.
                    <FormControl fullWidth >              
                      <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            fullWidth
                            options={props.contacts || []}
                            // onChange={handleUpdateUOM}
                            // onChange={handleContactChange}
                            // // disableCloseOnSelect
                            value={recipientList}
                            // onInputChange={handleInputChange}
                            onChange={(e, newValue) => {
                            // console.log('EMail change 282', e.target.value)
                            // console.log('New Value:', newValue)
                              if (typeof newValue === 'string') {
                              // console.log('283', newValue)
                              } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                              // console.log('INPUTVALUE 286', newValue.inputValue)
                              } else {
                              // console.log('288 New Value', e.target.value)
                                if (newValue && newValue.length) {
                                  let thisOne = newValue[newValue.length -1]
                                // console.log('This ONe!', thisOne)
                                  if (thisOne && thisOne.email) {
                                    setRecipientList(newValue)
                                  } else {
                                  // console.log('New Email address', thisOne)
                                    // Verify its a legit email
                                    let isValidEmail = validateEmail(thisOne)
                                  // console.log('Valid email??', isValidEmail)
                                    if (isValidEmail) {
                                      let newItem = {
                                        firstName: thisOne,
                                        lastName: '',
                                        email: thisOne
                                      }
                                      let recips = recipientList
                                      recips.push(newItem)
                                    // console.log('Updated recipient list', recips)
                                      setRecipientList(recips)
                                    } else {
                                      props.notification({
                                        type: 'warning',
                                        title: 'Invalid Email Address',
                                        message: `Sorry, but ${thisOne} is not a valid email address.`
                                      })
                                    }
                                    
                                  
                                  }
                                } else {
                                // console.log('Empty array')
                                  setRecipientList([])
                                }
                              }
                            }}
                            freeSolo
                            style={{marginTop: 25}}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                  {option.firstName} {option.lastName} - {option.email}
                              </React.Fragment>
                            )}
                            
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" label="Notification Recipients" placeholder="Who Should Receive A Copy of The Daily Logs?" helperText={`We must have at least one contact selected`} />
                            )}
                          /> 
                    </FormControl>
                    </div>
                  </Grid>
                
              ) : null
            }
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
              
              <FormControlLabel
                style={{marginLeft: 20}}
                control={<Checkbox checked={state.onSiteChecklist} onChange={ e => setState({...state, onSiteChecklist: !state.onSiteChecklist } ) } name="onSiteChecklist" />}
                label="Requests On-Site Checklist"
              />
              <FormControlLabel
                style={{marginLeft: 20}}
                control={<Checkbox checked={state.showHoursWorked} onChange={ e => setState({...state, showHoursWorked: !state.showHoursWorked } ) } name="showHoursWorked" />}
                label="Show Hours Worked"
              />

                <FormControlLabel
                style={{marginLeft: 20}}
                control={<Checkbox checked={state.singularBilling} onChange={ e => setState({...state, singularBilling: !state.singularBilling } ) } name="singularBilling" />}
                label="Single Bill For All Locations"
              />

              <FormControlLabel
                style={{marginLeft: 20}}
                control={<Checkbox checked={state.ignoreOnRoutes} onChange={ e => setState({...state, ignoreOnRoutes: !state.ignoreOnRoutes } ) } name="ignoreOnRoutes" />}
                label="Do Not Include On Routes/Schedules"
              />

              <FormControlLabel
                style={{marginLeft: 20}}
                control={<Checkbox checked={state.doNotSendHerbicideNotifications} onChange={ e => setState({...state, doNotSendHerbicideNotifications: !state.doNotSendHerbicideNotifications } ) } name="doNotSendHerbicideNotifications" />}
                label="Do Not Send Herbicide Notifications"
              />

              <FormControlLabel
                style={{marginLeft: 20}}
                control={<Checkbox checked={state.doNotSentHerbicideInvoices} onChange={ e => setState({...state, doNotSentHerbicideInvoices: !state.doNotSentHerbicideInvoices } ) } name="doNotSentHerbicideInvoices" />}
                label="Do Not Send Herbicide Invoices"
              />

              {
                state.customerType === 'ADOT' ? (
                  <FormControlLabel
                    style={{marginLeft: 20}}
                    control={<Checkbox checked={state.combineDumpInvoices} onChange={ e => setState({...state, combineDumpInvoices: !state.combineDumpInvoices } ) } name="combineDumpInvoices" />}
                    label="Combine All Dump Invoices"
                  />
                ) : null
              }
              {
                state.customerType === 'County' ? (
                  <FormControlLabel
                    style={{marginLeft: 20}}
                    control={<Checkbox checked={state.isNorthernPkwy} onChange={ e => setState({...state, isNorthernPkwy: !state.isNorthernPkwy } ) } name="isNorthernPkwy" />}
                    label="Northern Pkwy"
                  />
                ) : null
              }
               {
                state.customerType === 'County' ? (
                 <React.Fragment>
                  <FormControlLabel
                    style={{marginLeft: 20}}
                    control={<Checkbox checked={state.requiresCalendar} onChange={ e => setState({...state, requiresCalendar: !state.requiresCalendar } ) } name="requiresCalendar" />}
                    label="Requires Calendar"
                  />
                  <FormControlLabel
                    style={{marginLeft: 20}}
                    control={<Checkbox checked={state.requiresSupportingDocs} onChange={ e => setState({...state, requiresSupportingDocs: !state.requiresSupportingDocs } ) } name="requiresSupportingDocs" />}
                    label="Requires Supporting Docs"
                  />
                 </React.Fragment>
                ) : null
              }
              {/* <FormControlLabel
                style={{marginLeft: 20}}
                control={<Checkbox checked={state.poNumberRequired} onChange={ e => setState({...state, poNumberRequired: !state.poNumberRequired } ) } name="poNumberRequired" />}
                label="PO # Required"
              /> */}

            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} className={classes.gridItem}>
              <TextField
                id="billingFrequency"
                label="Billing Frequency"
                fullWidth
                // className={classes.paddedStuff}
                // type="number"
                select
                defaultValue="no"
                variant="standard"
                value= {state.billingFrequency}
                helperText="How Often Do We Bill?"
                onChange={ e => setState({...state, billingFrequency: e.target.value } ) }
                // onChange={ handleChange }
                >
                  <MenuItem key="an" value="As Needed">As Needed</MenuItem>
                  {/* <MenuItem key="an" value="Per Visit">Per Visit</MenuItem> */}
                  <MenuItem key="m" value="Monthly">Monthly</MenuItem>
                  <MenuItem key="q" value="Quarterly">Quarterly</MenuItem>
                </TextField>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} className={classes.gridItem}></Grid>
            {/* <Grid item lg={4} md={4} sm={4} xs={12} className={classes.gridItem}>
              <TextField
                id="date"
                label="First Payment Date"
                type="date"
                fullWidth
                value={moment(state.startDate).format('YYYY-MM-DD')}
                // value={moment(state.startDate).format('YYYY-MM-DD').toLocale } // "2017-05-24"}
                className={classes.textField}
                variant="standard"
                onChange={ e => setState({...state, startDate: e.target.value }) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> */}
            {/* <Grid item lg={4} md={4} sm={4} xs={12} className={classes.gridItem}>
            <TextField
              id="firstMonthPrice"
              label="First Month Price"
              fullWidth
              shrink={true}
              // className={classes.paddedStuff}
              // type="number"
              variant="standard"
              value= {state.firstMonthPrice}
              onChange={ e => setState({...state, firstMonthPrice:e.target.value } ) }
              // onChange={ (e) => updateMonthlyPrice( e.target.id, e.target.value) }
              />
            </Grid> */}
{/*             
           {
             (state.customerType !== 'Commercial' && state.customerType !== 'ADOT') ? (
               <React.Fragment>
              <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}>
              <TextField
                id="monthlyPrice"
                label="Price"
                fullWidth
                // className={classes.paddedStuff}
                // type="number"
                variant="standard"
                value= {state.monthlyPrice}
                // onChange={ e => setState({...state, monthlyPrice:e.target.value } ) }
                onChange={ (e) => updateMonthlyPrice( e.target.id, e.target.value) }
                helperText={`Price Per Billing Frequency`}
                />
            </Grid> 
            
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}>
              <TextField
                id="variableBilling"
                label="Variable Billing"
                fullWidth
                // className={classes.paddedStuff}
                // type="number"
                select
                defaultValue={false}
                variant="standard"
                value= {state.variableBilling}
                helperText="Do We Change The Amount We Bill By The Month?"
                onChange={ e => setState({...state, variableBilling: e.target.value } ) }
                // onChange={ handleChange }
                >
                  <MenuItem key="yes" value={true}>Yes</MenuItem>
                  <MenuItem key="no" value={false}>No</MenuItem>
                </TextField>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              { state.variableBilling === true ? (
          
                <VariableBilling client={props.client} saveVariableBilling={saveVariableBilling} monthlyFee={state.monthlyPrice} stuff={state} />

              ) : '' }
            </Grid>
          </React.Fragment>
             ) : null
           } */}
             <Grid item lg={12} md={12} sm={12} xs={12}>

                <SelectedMtcTasks url={props.url} list={list} selected={selectedTasks} addItem={handleAddNewTaskItem} updateParent={handleTaskSelections} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem} style={{ marginTop: '1rem', marginBottom: '1rem', paddingTop: '2rem', paddingBottom: '2rem', backgroundColor: 'rgba(23, 2, 4, 0.1)', borderRadius: 10 }}>
              <InvoiceLineItemsToShowUpComponent existingItems={state.invoiceLineItems} saveLineItem={handleLineItems} />
            </Grid>
           
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
              <JobSitesContainer {...state} updateJobsiteList={updateJobsites} removeJobSite={handleRemoveJobSite} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="fertilizations">Fertilizations</InputLabel>
                <Select
                  labelId="fertilizations-opetions"
                  id="fertilizations--schedule"
                  value={state.fertilizationSchedule}
                  onChange={(e) => setState({...state, fertilizationSchedule: e.target.value })}
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={12}>Monthly</MenuItem>
                  <MenuItem value={4}>Quarterly</MenuItem>
                  <MenuItem value={2}>Bi-Annually</MenuItem>
                  <MenuItem value={1}>Annually</MenuItem>
                </Select>
                <FormHelperText>Fertilization Schedule </FormHelperText>
              </FormControl>
            </Grid>
            {
              (state.fertilizationSchedule && state.fertilizationSchedule > 0) ? (
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                   <TextField
                    id="fertFee"
                    label="Fee Per Fertilization"
                    fullWidth
                    onFocus={handleFocus}
                    value={ state.fertilizationFee } // "2017-05-24"}
                    className={classes.textField}
                    variant="standard"
                    onChange={ e => setState({...state, fertilizationFee: e.target.value }) }
                    // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={'How Much Will We Charge Per Fertilization?'}
                  />
                </Grid>
              ) : null
            }
             <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="overseeding">Overseeding Schedule</InputLabel>
                <Select
                  labelId="overseeding-opetions"
                  id="overseeding--schedule"
                  value={state.overseedingSchedule}
                  onChange={(e) => setState({...state, overseedingSchedule: e.target.value })}
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={12}>Monthly</MenuItem>
                  <MenuItem value={4}>Quarterly</MenuItem>
                  <MenuItem value={2}>Bi-Annually</MenuItem>
                  <MenuItem value={1}>Annually</MenuItem>
                </Select>
                <FormHelperText>Overseeding Schedule </FormHelperText>
              </FormControl>
            </Grid>
            {
              (state.overseedingSchedule && state.overseedingSchedule > 0) ? (
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                   <TextField
                    id="overseedingFee"
                    label="Fee Per Overseeding"
                    fullWidth
                    onFocus={handleFocus}
                    value={ state.overseedingFee } // "2017-05-24"}
                    className={classes.textField}
                    variant="standard"
                    onChange={ e => setState({...state, overseedingFee: e.target.value }) }
                    // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={'How Much Will We Charge Per Overseeding?'}
                  />
                </Grid>
              ) : null
            }
            {/* {
              (props?.jobSites?.length < 2) ? (
                <> */}
                     <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel id="spraying">Spray Schedule (Herbicide Application Frequency)</InputLabel>
                          <Select
                            labelId="spraying-opetions"
                            id="spraying--schedule"
                            value={state.spraySchedule}
                            onChange={(e) => setState({...state, spraySchedule: e.target.value })}
                          >
                            <MenuItem value={0}>
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={12}>Monthly</MenuItem>
                            <MenuItem value={4}>Quarterly</MenuItem>
                            <MenuItem value={2}>Bi-Annually</MenuItem>
                            <MenuItem value={1}>Annually</MenuItem>
                          </Select>
                          <FormHelperText>Spray Schedule </FormHelperText>
                        </FormControl>
                      </Grid>
                      {
                          (state.spraySchedule && state.spraySchedule > 0) ? (
                            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                              <TextField
                                id="fertFee"
                                label="Fee Per Spray"
                                fullWidth
                                onFocus={handleFocus}
                                value={ state.sprayFee } // "2017-05-24"}
                                className={classes.textField}
                                variant="standard"
                                onChange={ e => setState({...state, sprayFee: e.target.value }) }
                                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                helperText={'How Much Will We Charge Per Spray?'}
                              />
                            </Grid>
                          ) : null
                        }
                {/* </>
              ) : null
            } */}
           
            
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
              <TextField
                id="notes"
                label="Notes"
                multiline

                minRows={4}
                variant="outlined"
                fullWidth
                onFocus={handleFocus}
                // className={classes.textSaveLineBreaks}
                value={state.notes}
                onChange={e => setState({...state, notes: e.target.value })}
                // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                // defaultValue="Default Value"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          {
            (props.contract && props.contract._id) ? (
              <Button color="primary" disabled={ loading || ((state.customerType === 'ADOT' && !state.jobName) || (state.customerType !== 'ADOT' && (!state.includedSites || state.includedSites.length < 1))) || (state.variableBilling === 'Yes' && !state.savedVariableBilling) } onClick={handleUpdate}>
              Update
              </Button>
            ) : (
              <Button color="primary" disabled={loading || ((state.customerType === 'ADOT' && !state.jobName) || (state.customerType !== 'ADOT' && (!state.includedSites || state.includedSites.length < 1))) || (state.variableBilling === 'Yes' && !state.savedVariableBilling)} onClick={handleSave}>
                Save
              </Button>
            )

          }
          
        </DialogActions>
      </Dialog>
  </div>
  )
  }

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      // crews: state.crews
    }
  }
  
  export default React.memo(connect(mapStateToProps)(NewMtcAgreement))
import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, IconButton, Badge }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HoursWorkedModal from '../BHL/HoursWorkedModal'
import ListOfClients from '../BHL/ListOfClients'
// import ServiceInputModal from './ServiceInputModal'
import InvoiceInputModal from '../BHL/InvoiceInputModal'
import ScanDetails from './UploadStuff/ScanDetailsPage'
import { FileUploader } from 'react-drag-drop-files';
import { useHistory } from "react-router-dom";
import RoomIcon from '@material-ui/icons/Room';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import ADOTLogData from './ADOTLogData'
import NorthernPkwyDataLog from './NorthernPkwyDataLog'
import DailyLogInputModal from './DailyLogInputModal'
import CountyDataLog from './CountyDataLog'
import PrintIcon from '@material-ui/icons/Print';
import PrintOptionsModal from '../MtcRoutes/PrintOptionsModal'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import ProtectedButton from '../../actions/protectedButtons'
import StorageIcon from '@material-ui/icons/Storage';
import PDFPreviewModal from '../Common/PDFViewerModal'
// '../Common/PDFViewerModal'

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  paper: {
    width: '100%',
    height: '100%',
    minHeight: '80vh',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  topRowButton: {
    margin: 45,
    marginTop: 10,
    marginBottom: 10,
    width: 276
  },
  fuelTankHeader: {
    fontSize: '1.1em',
    fontWeight: 600,
    color: '#aaa'
  },
  fuelTankData: {
    color: '#888',
    fontSize: '.9em'
  },
  historicalFuelDataLine: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row'
  },
  historicalFuelDataLineHeader: {
    fontSize: '1.1em',
    fontWeight: 600,
    color: '#aaa'
  },
  fuelTankData: {
    color: '#888',
    fontSize: '.9em'
  },
}))

const Dashboard = (props) => {
  // console.log('Dashboar dProps', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [showHoursWorkedModal, setShowHoursWorkedModal] = React.useState(false)
  const [clientList, setClientList] = React.useState([])
  const [crews, setCrews] = React.useState([])
  const [supplies, setSupplies] = React.useState([])
  const [takeOffs, setTakeOffs] = React.useState([])
  const [services, setServices] = React.useState([])
  const [showServiceInput, setShowServiceInput] = React.useState(false)
  const [showInputInvoiceModal, setShowInputInvoiceModal] = React.useState(false)
  const [activeMtcClients, setActiveMtcClients] = React.useState([])
  const [ADOTData, setADOTData] = React.useState([])
  const [northerPkwyData, setNorthernPkwyData] = React.useState([])
  const [countyData, setCountyData] = React.useState([])
  const [countySummaryData, setCountyDataSummary] = React.useState([])
  const [MCDOTSummaryData, setMCDOTSummaryData] = React.useState()
  const [itemToEdit, setItemToEdit] = React.useState({})
  const [mtcSites, setMtcSites] = React.useState([])
  const [smartTankData, setSmartTankData] = React.useState([])
  const [hmiCrews, setHMICrews] = React.useState([])
  let hmiEntity = 'd0e7d'

  // console.log('Hmi entity', hmiEntity)
  React.useEffect(() => {
    let isMounted = true

    const fetchActiveMtcAgreements = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/hmi/getActiveMtcAgreements`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          console.log('Ags:', response.data)
          if (isMounted) setActiveMtcClients(response.data)
        }
      })
    }
    const fetchClients = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/customer/clients?byEntity=610a290f45ef7427f94b2e78`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setClientList(response.data)
        }
      })
    }
    const fetchCrews = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/crews/listCrews`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          // console.log('Got Crews', response.data)
          // console.log('Is the component mounted?', isMounted)
          if (isMounted) {
            setCrews(response.data.crews)
            let newCrews = [...response.data.crews].filter(crew => {
              return (crew.entity.toString().includes(hmiEntity))
            })
            // console.log('HMI Crews', newCrews)
            setHMICrews(newCrews)
          }
        }
      })
    }

    const fetchSupplies = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/company/listBHLSupplies`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setSupplies(response.data)
        }
      })
    }

    const fetchTakeOffs = async () => {
      axios.get(`${props.url}/api/company/listTakeOffs`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
        // console.log('Takeoffs', response.data)
          if (isMounted) setTakeOffs(response.data)
        }
      })
    }

    const fetchServices = async () => {
      axios.get(`${props.url}/api/company/listservices`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
        // console.log('Services', response.data)
          if (isMounted) setServices(response.data)
        }
      })
    }

    const fetchADOTInformation = async () => {
      axios.get(`${props.url}/api/hmi/fetchADOTLogs`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
        // console.log('Services', response.data)
          if (isMounted) {
            setADOTData(response.data.adot)
            setNorthernPkwyData(response.data.npkwy)
            setMCDOTSummaryData(response.data.mcdot)
            setCountyData(response.data.county)
            setCountyDataSummary(response.data.countySummary)
          }
        }
      })
    }
    const fetchAllMtcSites = async () => {
      axios.get(`${props.url}/api/hmi/getActiveSites`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data && response.data.length) {
        // console.log('Services', response.data)
          if (isMounted && response.data) {
            setMtcSites(response.data)
          }
        }
      })
    }
    
    const fetchFuelSmartData = async () => {
      console.log('Fetch Fuel Tank Data')
      axios.get(`${props.url}/api/hmi/fetchFuelSmartData`).then((response, status, data) => {
        // setLoading(false)
        if (response && response.data) {
        console.log('Smart Tank Data', response.data)
          if (isMounted && response.data.length) {
            // setMtcSites(response.data.markers)
            console.log('HEre is the fuel data', response.data)
            setSmartTankData(response.data)
          }
        }
      })
    }
    

    fetchClients()
    fetchCrews()
    fetchSupplies()
    fetchTakeOffs()
    fetchServices()
    fetchActiveMtcAgreements()
    fetchADOTInformation()
    fetchAllMtcSites()
    fetchFuelSmartData()

    socket.on(`hmidailylogs`, (data) => {
      // console.log('Socket...', data)
      handleSocketDataUpdate(data)
    })

    return () => {
      isMounted = false
      socket.off(`hmidailylogs`)
    }
  }, [])

  const handleSocketDataUpdate = (data) => {
    // console.log('This hsould be updated data....', data)
    setADOTData(data.adot)
    setNorthernPkwyData(data.npkwy)
    setMCDOTSummaryData(data.mcdot)
    setCountyData(data.county)
    setCountyDataSummary(data.countySummary)
  }

  const handleInputInvoice = () => {
    setShowInputInvoiceModal(true)
  }

  const handleInputHoursWorked = () => {
    setShowHoursWorkedModal(true)
  }

  const handleInputServices = () => {
    setShowServiceInput(true)
  }

  const TopRow = (props) => {
    return (
      <>
        <HoursWorkedModal  open={showHoursWorkedModal} clients={activeMtcClients} crews={crews} closeModal={e => setShowHoursWorkedModal(false)} supplies={supplies} services={services} takeoffs={takeOffs} />
        {/* <ServiceInputModal open={showServiceInput} clients={clientList} crews={crews} closeModal={e => setShowServiceInput(false) } /> */}
        <InvoiceInputModal open={showInputInvoiceModal} clients={clientList} closeModal={e=>setShowInputInvoiceModal(false)} />
       {/* <Button className={classes.topRowButton} style={{backgroundColor: '#cc8a3f'}} variant="contained" size="small" startIcon={<AddCircleIcon />} onClick={handleInputServices}>
          Input Services Performed
        </Button> */}
        <Button className={classes.topRowButton} disabled={loading} style={{backgroundColor: '#3fc7cc'}} variant="contained" size="small" startIcon={<AddAlarmIcon />} onClick={handleInputHoursWorked}>
          Input Service Visit Details
        </Button>
        <Button className={classes.topRowButton} style={{backgroundColor: '#cc3f4d'}} variant="contained" size="small" startIcon={<NoteAddIcon />} onClick={handleInputInvoice}>
          Input Invoice
        </Button>
      </>
    )
  }

  const handleCompletionOfUpload = (data) => {
  // console.log('Uplaod complete')
    props.notification({
      type: 'success',
      title: 'Upload Complete!',
      message: 'Great work!'
    })
  }

  const handleAddADOTLog= (newLog) => {
    console.log('Add this log', newLog)
    let newData = [...ADOTData, newLog ]
    console.log('New Data', newData)
    setADOTData(newData)
  }

  const handleAddToNorthernParkway = (addlData) => {
    let newData = [...northerPkwyData, addlData]    
    setNorthernPkwyData(newData)
  }

  const handleEditNorthernPkwyItem = (itemToEdit) => {
    console.log('Edit this', itemToEdit)
    if (itemToEdit && itemToEdit.jobSite && itemToEdit.jobSite._id) {

    } else if (itemToEdit && itemToEdit.jobSite) {    
      let sites = [...mtcSites]
      // console.log('Find the jobsite...', sites)
      console.log('Looking for the site to edit...', itemToEdit)
      let site = sites.find(item => {
        console.log('Compare it to:', item)
        return (item._id.toString() === itemToEdit?.jobSite.toString())
      })
      if (site && site._id) {
        itemToEdit.jobSite = site
      }
    }
    console.log('Item to edit w/ JOBsite!!', itemToEdit)
    setItemToEdit(itemToEdit)
  }

  const handleDeleteNorthernPkwyItem = (itemToDelete) => {
    console.log('Delete this:', itemToDelete)
    if (window.confirm('Are you sure you wish to delete this log data?')) {
      let obj = {
        ...itemToDelete
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/hmi/deleteDailyLogItem`,
        data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
      console.log('Delete:', response.data)
      let newData = [...northerPkwyData ]
      let index = newData.findIndex(item => (item._id === itemToDelete._id))
      if (index > -1) {
        newData.splice(index, 1)
        setNorthernPkwyData(newData)
      }
      })
 
    }
  }

  const handleEditCountyItem = (itemToEdit) => {
    console.log('Edit this County item', itemToEdit)
    if (itemToEdit && itemToEdit.jobSite && itemToEdit.jobSite._id) {

    } else {    
      let sites = [...mtcSites]
      // console.log('Find the jobsite...', sites)
      console.log('Looking for the site to edit...', itemToEdit)
      let site = sites.find(item => {
        console.log('Compare it to:', item)
        return (itemToEdit && itemToEdit.jobSite && item._id.toString() === itemToEdit.jobSite.toString())
      })
      if (site && site._id) {
        itemToEdit.jobSite = site
      }
    }
    console.log('Item to edit w/ JOBsite!!', itemToEdit)
    setItemToEdit(itemToEdit)
  }

  const handleDeleteCountyItem = (itemToDelete) => {
    console.log('Delete this:', itemToDelete)
    if (window.confirm('Are you sure you wish to delete this log data?')) {
      let obj = {
        ...itemToDelete
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/hmi/deleteDailyLogItem`,
        data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
      console.log('Delete:', response.data)
      let newData = [...northerPkwyData ]
      let index = newData.findIndex(item => (item._id === itemToDelete._id))
      if (index > -1) {
        newData.splice(index, 1)
        setNorthernPkwyData(newData)
      }
      })
 
    }
  }

  const handleRemoveDailyLogItem = (itemToRemove) => {
    // console.log('Remove this one:', itemToRemove)
    if (window.confirm('Are you sure you wish to delete this log data?')) {
      let obj = {
        ...itemToRemove
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/hmi/deleteDailyLogItem`,
        data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
      // console.log('Repsonse:', response.data)
      let newData = [...ADOTData]
      let index = newData.findIndex(item => (item._id === itemToRemove._id))
      if (index > -1) {
        newData.splice(index, 1)
        setADOTData(newData)
      }
      })
 
    }
  
  }

  const handleEditeDailyLogItem = (itemToEdit) => {
    // console.log('Edit htis one', itemToEdit)
    setItemToEdit(itemToEdit)
  }

  const handleSyncLogData = (data, type) => {
    console.log('Sync our data', data)
    if (type === 'update') {
      console.log('Update to make', data)
      const logType = data.logType
      if (logType === 'ADOT') {
        let index = ADOTData.findIndex(item => (item._id === data._id))
        if (index > -1) {
          // console.log('Index', index)
          let newLogs = [...ADOTData]
          let theItem = {
            ...newLogs[index]
          }
          // console.log('Item before update', theItem)
          const updatedLogItem = {
            ...data,
            client: data?.client?._id,
            crew: data?.crew?._id,
            mtcAgreement: data?.mtcAgreement?._id,
            
          }
          // console.log('Updated AFter:', updatedLogItem)
          newLogs[index] = updatedLogItem
          // console.log('New Logs', newLogs)
          console.log(newLogs[index])
          console.log(ADOTData[index])
          // console.log('Update our shit...')
          setADOTData(newLogs)
        }
      }
      setItemToEdit({})
      // if (data.itemToEdit && data.itemToEdit._id) {
      //   // console.log('Need to sync up our edited item too...', data.itemToEdit)
      //   setItemToEdit(data.itemToEdit)
      // }
    } else {
      console.log('NON UPDATE')
      setADOTData(data.adot)
      setNorthernPkwyData(data.npkwy)
      setMCDOTSummaryData(data.mcdot)
      setCountyData(data.county)
      setCountyDataSummary(data.countySummary)
      if (data.itemToEdit && data.itemToEdit._id) {
        // console.log('Need to sync up our edited item too...', data.itemToEdit)
        setItemToEdit(data.itemToEdit)
      }
    }
  }

  // smartTankData.totalGallons ? smartTankData.totalGallons : 'Unknown'
  // console.log(smartTankData)
  console.log('518 crews', hmiCrews)
  return (
    <div className={classes.root}>
     
      <Paper className={classes.paper}>
        <h1>HMI Dashboard</h1>
        {/* <TopRow /> */}
        <ActionArea url={props.url} notification={props.notification} mtcSites={mtcSites} itemToEdit={itemToEdit} setItemToEdit={setItemToEdit} crews={hmiCrews} ags={activeMtcClients} addADOTLog={handleAddADOTLog} addNpkwyData={handleAddToNorthernParkway} updateOurLogs={handleSyncLogData} />
        {/* <UploadArea {...props} uploadComplete={handleCompletionOfUpload} /> */}
        {/* <ScanDetails /> */}
        <div style={{ padding: '10px', margin: '10px', border: '2px solid #ccc', borderRadius: 7, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
          Projected Revenue
        </div>
        <div style={{ padding: '10px', margin: '10px', border: '2px solid #ccc', borderRadius: 7, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
          <img src="https://www.smartank.com/portal/App_Themes/SB/images/tanklink-logo.gif" width="200" />
          
             { (smartTankData && smartTankData.length) ? <SmartTankData data={smartTankData} /> : 'Sync Error'  }
          
        </div>
        <NorthernPkwyDataLog data={northerPkwyData} ags={activeMtcClients} mcdotData={MCDOTSummaryData} deleteDailyLogItem={handleDeleteNorthernPkwyItem} editeDailyLogItem={handleEditNorthernPkwyItem} />
        <ADOTLogData data={ADOTData} ags={activeMtcClients} deleteDailyLogItem={handleRemoveDailyLogItem} editDailyLogItem={handleEditeDailyLogItem} />
        <CountyDataLog data={countyData} ags={activeMtcClients} summaryData={countySummaryData} deleteDailyLogItem={handleDeleteCountyItem} editeDailyLogItem={handleEditCountyItem} />
      </Paper>
    </div>
  )
}

const SmartTankData = ({ data }) => {
  const classes = useStyles()
  const [showSmartTankModal, setShowSmartTankModal] = React.useState(false)
  // console.log('Smart Tank Data:', data)
  const currentTankData = data[0]
  const handleViewHistorical = () => {
    // console.log('Show hsitorical data')
    setShowSmartTankModal(true)
  }

  const handleCloseModal = () => {
    setShowSmartTankModal(false)
  }

  return (
    <div>
      <HistoricalSmartTankDataModal open={showSmartTankModal} data={data} closeModal={handleCloseModal} />
      <div className={classes.fuelTankHeader}>
        Tank Data
        <IconButton onClick={handleViewHistorical}aria-label="view" color="secondary"
              
              >
                <Badge color="secondary" badgeContent={data.length}>
                  <LibraryAddIcon />
                </Badge>
              
                </IconButton>
        
     </div>
      <div className={classes.fuelTankData}>Time: {moment(currentTankData.inventory_time).format('MM/DD/YY hh:mma') || ''}</div>
      <div className={classes.fuelTankData}>Level: {currentTankData.level || ''}</div>
      <div className={classes.fuelTankData}>Gallons: {currentTankData.inventory || ''}</div>
      <div className={classes.fuelTankData}>Percentage: {`${currentTankData.percentage}% Full` || ''}</div>
    </div>
  )
}

const HistoricalSmartTankDataModal = ({ open, data, closeModal }) => {
  const classes = useStyles()
  return (
    <Dialog
    open={open}
    maxWidth="md"
    fullWidth
    onClose={closeModal}
  >
    <DialogTitle>Historical Tank Data</DialogTitle>
    <DialogContent>
      <div >
        {
          data.map(line => {

            return (
              <div key={line._id} className={classes.historicalFuelDataLine}>
                  <div className={classes.fuelTankData}>Time: {moment(line.inventory_time).format('MM/DD/YY hh:mma') || ''}</div>
                  <div className={classes.fuelTankData}>Level: {line.level || ''}</div>
                  <div className={classes.fuelTankData}>Gallons: {line.inventory || ''}</div>
                  <div className={classes.fuelTankData}>Percentage: {`${line.percentage}% Full` || ''}</div>
              </div>
            )
          })
        }
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeModal}>CLOSE</Button>
    </DialogActions>
  </Dialog>
  )
}
const ActionArea = (props) => {
  // console.log('action area', props)
  const classes = useStyles();
  const history = useHistory();
  const [showDailyLogInputModal, setShowDailyLogInputModal] = React.useState(false)
  const [showADOTModal, setShowADOTModal] = React.useState(false)
  const [showNorthernPkwyModal, setShowNorthernPkwyModal] = React.useState(false)
  const [showPrintOptionsModal, setShowPrintOptionsModal] = React.useState(false)
  const [showPDF, setShowPDF] = React.useState(false)
  const [pdf, setPDF] = React.useState({})

  React.useEffect(() => {
    if (props.itemToEdit && props.itemToEdit._id) setShowDailyLogInputModal(true)
  }, [props.itemToEdit])

  const handleCommercialMonthEnd = () => {
    const msg = `Are you sure you wish to generate REAL invoices for ALL ACTIVE Commercial Maintenance Agreements?? Clicking OK will result in emails being sent to real customer email addresses.`
    if (window.confirm(msg)) {
      let obj = {
        run: true
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/hmi/invoiceCommercialMaintenance`,
        data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
      // console.log('Repsonse:', response.data)
      })
    }
  // console.log('Obj', obj)

  }

  const handleViewMap = () => {
    history.push('/hmimapview')
  }

  const handleViewMtcMap = () => {
    history.push('/hmimtcmapview')
  }

  const handleCountyMonthEnd = () => {
    const msg = `Are you sure you wish to generate invoice for all ACTIVE COUNTY Maintenance customers?`
    if (window.confirm(msg)) {
      let obj = {
        run: true
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/hmi/invoiceCountyMaintenance`,
        data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
      // console.log('Repsonse:', response.data)
      })
    }
  }

  const handleADOTInput = () => {
    setShowADOTModal(true)
  }

  const handleResponseFromADOTModal = (data) => {
    if (data === 'close') setShowADOTModal(false)
    if (data && data._id) {
      props.addADOTLog(data)
      props.notification({
        type: 'success',
        title: 'Saved ADOT Log Info',
        message: 'We Saved Those Hours!'
      })
    }
    // setShowADOTModal(false)
  }

  const handleResponseFromNPModal = (data) => {
    console.log(data)
    if (data === 'close') setShowNorthernPkwyModal(false)
    if (data && data._id) {
      props.addNpkwyData(data)
      props.notification({
        type: 'success',
        title: 'Saved Northern Parkway Log Info',
        message: 'We Saved Those Hours!'
      })
    }
  }

  const handleADOTMonthEnd = () => {
    const msg = `Are you sure you wish to generate REAL invoices for ALL ACTIVE ADOT Maintenance Agreements?? Clicking OK will NOT result in emails being sent to real customer email addresses - but Susan and Chad will receive them.`
    if (window.confirm(msg)) {
      let obj = {
        run: true
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/hmi/invoiceADOT`,
        data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
      console.log('Repsonse:', response.data)
      if (response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'ADOT Invoices Generated',
          message: 'Invoices Have Been Generated!'
        })
      }
      })
    }
  }

  const handleNPInput = () => {
    setShowNorthernPkwyModal(true)
  }

  const handleChangeToAssignedCrews = () => {
    history.push('/hmicrewmtcassignment')
  }

  const handleRouteToMtcSchedule = () => {
    history.push('/hmimtcschedule')
  }

  const handleDailyLogInput = () => {
    setShowDailyLogInputModal(true)
  }

  const handleResponseFromDailyInput = (data, type) => {
    console.log('UPDATE ', type)
    console.log(data)
    if (type === 'close') {
      setShowDailyLogInputModal(false)
      props.setItemToEdit({})
    }
    if (type === 'new') {
      console.log('We have nwe logs to add to our data...', data)
    }
    if (type === 'update') {
      // props.updateOurLogs(data)
      setShowDailyLogInputModal(false)
      console.log('Edited item...', data)
      props.updateOurLogs(data, type)
      // props.setItemToEdit({})
    }
    // console.log('Daily Log Response:', data)
    // Sync the Dashboard Here and stuff
  }

  const handlePrintTomorrowsSchedule = () => {
    console.log('print tomorrow')
    setShowPrintOptionsModal(true)

  }

  const handleClosePrintOptionsModal = (d)=> {
    setShowPrintOptionsModal(false)
  }

  const handlePrintSchedule = (data) => {
    console.log('Print schedule...', data)
    setPDF(data)
    setShowPDF(true)
        // axios.get(`${props.url}/api/hmi/printTomorrowsSchedule`).then((response, status, data) => {
    //   if (response && response.data) {
    //     console.log('Data to Print', response.data)
    //     // if (isMounted) setActiveMtcClients(response.data)
    //   }
    // })
  }
  const handleClosePDF = () => {
    setShowPDF(false)
    
  }
  
  const DustinsFilteredCrews = [...props.crews].filter(crew => {
    if (crew.division !== 'ADOT') return crew
  })

  const handleAssetInventory = () => {
    history.push('/hmiirrigationasset')
  }

  const handleViewDailyLogs = () => {
    history.push('/hmidailylogs')
  }

  const handleViewDailyLogDashboard = () => {
    history.push('/hmilogdashboard')
  }

  const handleViewMobileAppView = () => {
    history.push('/simulatemobileappview')
  }

  const handleViewOfficeDashboard = () => {
    history.push('/hmiofficedashboard')
  }
  const handleSiteReviews = () => {
    history.push('/hmisitereviews')
  }
  const handleRouteToDailyScheduleView = () => {
    history.push('/dailyscheduleview')
  }

  const handleRouteToPage = (page) => {
    history.push(page)
  }

  return (
    <div>
       <PDFPreviewModal open={showPDF} pdf={pdf} handleClose={handleClosePDF} />
       {/* <PDFArea pdf={pdf} open={showPDF} closeModal={handleClosePDF} /> */}
       <PrintOptionsModal open={showPrintOptionsModal} isHMI={true} closeModal={handleClosePrintOptionsModal} url={props.url} crews={DustinsFilteredCrews} handleShowPDF={handlePrintSchedule}  />
      <DailyLogInputModal notification={props.notification} url={props.url} mtcSites={props.mtcSites} open={showDailyLogInputModal} itemToEdit={props.itemToEdit} ags={props.ags} crews={props.crews} updateParent={handleResponseFromDailyInput} />
        {/* <ADOTDataInputModal url={props.url} open={showADOTModal} ags={props.ags} crews={props.crews} updateParent={handleResponseFromADOTModal} />
          <NorthernPkwyDataInputModal url={props.url} open={showNorthernPkwyModal} ags={props.ags} crews={props.crews} updateParent={handleResponseFromNPModal} /> */}
        {/* <Button className={classes.topRowButton} style={{backgroundColor: '#cc3f4d'}} variant="contained" size="small" startIcon={<NoteAddIcon />} onClick={handleADOTMonthEnd} >
          Invoice ADOT
        </Button> */}
         {/* <Button className={classes.topRowButton} style={{backgroundColor: '#cc3f4d'}} variant="contained" size="small" startIcon={<NoteAddIcon />} onClick={handleCountyMonthEnd} >
            Invoice County Mtc
        </Button> */}
         {/* <Button className={classes.topRowButton} style={{backgroundColor: '#cc3f4d'}} variant="contained" size="small" startIcon={<NoteAddIcon />} onClick={handleCommercialMonthEnd} >
            Invoice Commercial Mtc
        </Button>  */}
       <ProtectedButton type="HMI_Invoice" kind="update" perms={props.perms || []}>
          <Button className={classes.topRowButton} style={{backgroundColor: '#cc3f4d'}} variant="contained" size="small" startIcon={<NoteAddIcon />} onClick={handleCommercialMonthEnd} >
            Invoice Commercial Mtc
          </Button>
       </ProtectedButton>
       <ProtectedButton type="HMI_Invoice" kind="update" perms={props.perms || []}>
        <Button className={classes.topRowButton} style={{backgroundColor: '#cc3f4d'}} variant="contained" size="small" startIcon={<NoteAddIcon />} onClick={handleCountyMonthEnd} >
          Invoice County Mtc
        </Button>
      </ProtectedButton>
      <ProtectedButton type="HMI_Invoice" kind="update" perms={props.perms || []}>
        <Button className={classes.topRowButton} style={{backgroundColor: '#cc3f4d'}} variant="contained" size="small" startIcon={<NoteAddIcon />} onClick={handleADOTMonthEnd} >
          Invoice ADOT
        </Button>
      </ProtectedButton>
     
      <Button className={classes.topRowButton} style={{backgroundColor: '#00d084'}} variant="contained" size="small" startIcon={<RoomIcon />} onClick={handleViewMap} >
       Irrigation Map
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#00d084'}} variant="contained" size="small" startIcon={<RoomIcon />} onClick={handleViewMtcMap} >
        Mtc Map View
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#02f2aa'}} variant="contained" size="small" startIcon={<LibraryAddIcon />} onClick={handleChangeToAssignedCrews} >
        View Assigned Crews
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#02f2aa'}} variant="contained" size="small" startIcon={<LibraryAddIcon />} onClick={handleRouteToMtcSchedule} >
        View Mtc Schedule
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#aaeeff'}} variant="contained" size="small" startIcon={<LibraryAddIcon />} onClick={handleDailyLogInput} >
        Input Daily Log
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#195570', color: '#ddd'}} variant="contained" size="small" startIcon={<PrintIcon />} onClick={handlePrintTomorrowsSchedule} >
        Print Schedule
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#121393', color: '#ddd'}} variant="contained" size="small" startIcon={<RoomIcon />} onClick={handleAssetInventory} >
        Irrigation Asset Map
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#121393', color: '#ddd'}} variant="contained" size="small" startIcon={<RoomIcon />} onClick={handleViewDailyLogs} >
        View Daily Logs
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#cb81f0', color: '#ddd'}} variant="contained" size="small" startIcon={<StorageIcon />} onClick={handleViewDailyLogDashboard} >
        View Log Dashboard
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#a71144', color: '#ddd'}} variant="contained" size="small" startIcon={<StorageIcon />} onClick={handleViewMobileAppView} >
        Mobile App View
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#121393', color: '#ddd'}} variant="contained" size="small" startIcon={<RoomIcon />} onClick={handleViewOfficeDashboard} >
        Office Dashboard
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#121393', color: '#ddd'}} variant="contained" size="small" startIcon={<RoomIcon />} onClick={handleSiteReviews} >
        View Site Reviews
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#1ae', color: '#ddd'}} variant="contained" size="small" startIcon={<RoomIcon />} onClick={handleRouteToDailyScheduleView} >
        Scheduling Tool
      </Button>
      <Button className={classes.topRowButton} style={{backgroundColor: '#af9', color: '#333'}} variant="contained" size="small" startIcon={<RoomIcon />} onClick={() => handleRouteToPage('/spraylogdashboard')} >
        Spray Log History
      </Button>
    </div>
  )
}

const UploadArea = (props) => {
  const [uploads, setUploads] = React.useState([])
  const [vendors, setVendors] = React.useState([])
  const [purchaseOrders, setPOs] = React.useState([])
  const [purchaseOrder, setPO] = React.useState({})
  const [filesToAdd, setNewFiles] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0,
    projects: [],
    filesToUpload: []
  })
  const [vendorInvoiceInputModalOpen, setVendorInvoiceInputModalOpen] = React.useState(false)
  const [showUploadStatusModal, setShowUploadStatusModal] = React.useState(false)
  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "XLSX", "CSV", "TXT", "JPEG"];
  const [file, setFile] = React.useState([])

  const uploadFilesNow = (fileList) => {
    // console.log('Time to upload this shit!!', fileList)
    let formData = new FormData()
    fileList.forEach(file => {
      // console.log('Append to formdata:', file)
      file.scanType = 'hmiScan'
      formData.append('theFileList', file)
    })
    // console.log('Form Data: ', formData)
    let url = `${props.url}/api/projects/uploadProjectFilesMultiple?type=hmiScan`
    axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressEvent => {
        // console.log('Upload Progress', progressEvent)
      }
    }).then(resp => {
    // console.log('REsponse from axios::', resp)
      let allGood = true
      if (resp && resp.data && resp.data.duplicateFiles && resp.data.duplicateFiles.length) {
        allGood = false
      }
      if (resp && resp.data && resp.data.errorFiles && resp.data.errorFiles.length) {
        allGood = false
      }
      props.uploadComplete(resp.data)
    })
  }

  const handleChange = (fileList) => {
    // console.log('FILES TO UPLOAD: ', fileList)
    let files = Array.from(fileList)
    // console.log('files', files)
    // fileList.map(item => (files.push(item)))
    // console.log('files: ', files)
    let newFiles = file.concat(files)
    setFile(newFiles)
    setState({...state, filesToUpload: newFiles})
  // console.log('New Files to upload:', newFiles)
    // UPload the files now
    uploadFilesNow(files)
  };

  const handleDragChange= (e, d) => {
    // console.log('Drag change', e)
    // console.log(d)
  }

  const handleSelect = (e, d) => {
    // console.log('Select: ', e)
    // console.log(d)
  }

  return (
    <FileUploader
      handleChange={handleChange}
      name="file" 
      multiple
      fileOrFiles='Array'
      onSelect={handleSelect}
      onDraggingStateChange={handleDragChange}
      >
        <div style={{ marginBottom: 15, textAlign: 'center', border: '3px dashed #ccc', borderRadius: '10px', padding: '15px', cursor: 'pointer' }}>
          Click Here to Upload or Drag and Drop Files Here
        </div>
      </FileUploader>
  )
}

const PDFArea = ({ pdf, open, closeModal }) => {

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      onClose={closeModal}
    >
      <DialogTitle>Maintenance Schedule</DialogTitle>
      <DialogContent>
        <div style={{ height: '1000px' }}>
          {/* <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="100%" /> */}
         
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    entities: state.entities,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(Dashboard)
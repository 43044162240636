import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import { Button, CircularProgress, TextField, Typography, Chip, TableContainer }  from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { currencyFormat } from '../../../services/common'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import StatusModal from './StatusModal'
import Checkbox from '@material-ui/core/Checkbox';
import BulkEditDialog from './BulkUpdateComponent';

const handleFocus = (event) => event.target.select();

function debounce(func, wait) {
  let timeout;
  const debouncedFunc = function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
  debouncedFunc.cancel = () => clearTimeout(timeout);
  return debouncedFunc;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 15,
    height: '95vh'
  },
  paper: {
    width: '100%',
    margin: 5,
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 300px)', // Adjust based on your layout
    '& .MuiTableCell-root': {
      // Add CSS containment for better performance
      contain: 'content',
    },
  },
  // Use transform instead of opacity for better performance
  fadeCell: {
    transform: 'translateZ(0)',
    willChange: 'transform',
  },
  tableBodyWrapper: {
    overflowX: 'scroll',
    minHeight: '40vh',
    maxHeight: '65vh'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    fontSize: '1.2em'
  },
  rowContainer: {
    // border: '3px solid red',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rowItem: {
    // border: '2px solid blue',
    fontSize: '1.25em',
    padding: 5,
    margin: 5
    // marginRight: 15
  },
  rowImage: {
    // border: '1px solid green',
    display: 'flex',
    alignSelf: 'stretch'
    // margin: -10,
    // padding: -5,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  logo: {
    width: 'auto',
    maxHeight: 24,
    marginRight: theme.spacing(1)
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5)
  },
  clientInfo: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  statusChip: {
    height: 24,
    padding: theme.spacing(0, 1)
  },
  separator: {
    margin: theme.spacing(0, 1),
    color: theme.palette.text.secondary
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    position: 'relative', // Add this
    zIndex: 1,           // Add this
  },
  estimateAction: {
    position: 'relative', // Add this
    zIndex: 2            // Add this - higher than container
  }
}));

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

// const headCells = [
//   { id: 'name', numeric: false, disablePadding: true, label: 'Estimate Name' },
//   { id: 'entity', numeric: false, disablePadding: false, label: 'Entity' },
//   // { id: 'number', numeric: false, disablePadding: true, label: 'Estimate #' },
//   { id: 'contractor', center: false, disablePadding: true, label: 'Contractor' },
//   { id: 'clientName', numeric: false, disablePadding: true, label: 'Customer Name' },
//   { id: 'amount', numeric: false, disablePadding: true, label: 'Amount' },
//   { id: 'created_at', numeric: false, disablePadding: true, label: 'Created' },
//   // { id: 'created_by', numeric: false, disablePadding: true, label: 'Created By' },
//   { id: 'salesRep', numeric: false, disablePadding: true, label: 'Sales Rep' },
//   { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
//   { id: 'updated_at', numeric: false, disablePadding: true, label: 'Last Update' },
//   // { id: 'updated_by', numeric: false, disablePadding: true, label: 'Updated By' },
//   { id: 'action', numeric: false, disablePadding: true, label: 'Action' },
// ];
const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Estimate' },
  { id: 'info', numeric: false, disablePadding: false, label: 'Details' },
  { id: 'created_at', numeric: false, disablePadding: true, label: 'Created' },
  { id: 'updated_at', numeric: false, disablePadding: true, label: 'Last Update' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Amount' },
  { id: 'confidence', numeric: true, disablePadding: false, label: 'Confidence %' },
  { id: 'expectedCloseDate', numeric: false, disablePadding: false, label: 'Expected Close' },
  { id: 'duration', numeric: true, disablePadding: false, label: 'Duration (months)' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' }
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onClick={onSelectAllClick}
            onChange={() => {}}
            inputProps={{ 'aria-label': 'select all estimates' }}
            style={{ cursor: 'pointer' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const ListOfOpenEstimates = (props) => {
  const classes = useStyles()
  // console.log("List of OPen EstimateS", props)
  const [filteredRows, setFilteredRows] = React.useState([])
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [rows, setRows] = React.useState([])
  const [searchValue, setSearchValue] = React.useState('')
  // const [selected, setSelected] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [needToSave, setNeedToSave] = React.useState(false)
  const [dataToSave, setDataToSave] = React.useState([])
  const [pendingUpdates, setPendingUpdates] = React.useState(new Map());
  const [unsavedChanges, setUnsavedChanges] = React.useState(new Map());
  const [showSaveBar, setShowSaveBar] = React.useState(false);
  const [showBulkEdit, setShowBulkEdit] = React.useState(false);

  const updateTimeoutRef = React.useRef(null);

  const SaveChangesBar = ({ onSave, onCancel }) => (
    <div style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '16px',
      backgroundColor: '#f5f5f5',
      borderTop: '1px solid #ddd',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '16px',
      zIndex: 1000,
    }}>
      <Button
        variant="outlined"
        color="secondary"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onSave}
      >
        Save Changes
      </Button>
    </div>
  );

  React.useEffect(() => {
    if (props.list?.length > 0) {
      setRows(props.list);
      setFilteredRows(props.list);
    }
  }, [props.list]);

  const debouncedUpdate = React.useCallback(() => {
    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }
  
    updateTimeoutRef.current = setTimeout(() => {
      const updates = Array.from(pendingUpdates.entries()).map(([id, data]) => ({
        estimateId: id,
        dataToUpdate: data
      }));
  
      if (updates.length > 0) {
        setLoading(true); // Show loading state
        axios({
          method: 'post',
          url: `${props.url}/api/proposal/modifyOpenEstimates`,
          data: updates.length === 1 ? updates[0] : { updates } // Format differently for single vs bulk updates
        })
        .then(response => {
          if (response.data) {
            const updatedData = Array.isArray(response.data) ? response.data : [response.data];
            
            // Update the filtered rows with new data
            setFilteredRows(prev => 
              prev.map(row => {
                const updated = updatedData.find(u => u._id === row._id);
                return updated || row;
              })
            );
            
            // Update the main rows array as well
            setRows(prev => 
              prev.map(row => {
                const updated = updatedData.find(u => u._id === row._id);
                return updated || row;
              })
            );
  
            // Notify parent component
            props.updateParent(response.data);
          }
          setPendingUpdates(new Map());
        })
        .catch(e => {
          console.error('Error updating estimates:', e);
          // Optionally revert the local changes on error
        })
        .finally(() => {
          setLoading(false);
        });
      }
    }, 1000);
  }, [pendingUpdates, props.url, props.updateParent]);

  const handleBulkUpdate = async (updates) => {
    setLoading(true);
    try {
      const response = await axios({
        method: 'post',
        url: `${props.url}/api/proposal/modifyOpenEstimates`,
        data: {
          estimateIds: selected,
          dataToUpdate: updates
        }
      });
  
      if (response.data) {
        props.notification({
          type: 'success',
          title: `Updated Our Estimates`,
          message: `Updates are Complete`
        })
        // Update local state
        setFilteredRows(prev => 
          prev.map(row => {
            if (selected.includes(row._id)) {
              return { ...row, ...updates };
            }
            return row;
          })
        );
        
        // Update main array
        setRows(prev => 
          prev.map(row => {
            if (selected.includes(row._id)) {
              return { ...row, ...updates };
            }
            return row;
          })
        );
  
        // Close dialog and clear selection
        setShowBulkEdit(false);
        setSelected([]);
        
        // Notify parent
        // props.updateParent(response.data);
      }
    } catch (error) {
      console.error('Error updating estimates:', error);
    } finally {
      setLoading(false);
    }
  };

  // Cleanup timeout on unmount
  React.useEffect(() => {
    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
    };
  }, []);

  const displayData = React.useMemo(() => {
    const sorted = stableSort(filteredRows, getSorting(order, orderBy));
    return sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredRows, order, orderBy, page, rowsPerPage]);

  // Optimize the field change handler
const handleFieldChange = React.useCallback((id, field, value, index) => {
  // Update local UI state immediately
  setFilteredRows(prev => {
    const newRows = [...prev];
    if (newRows[index]) {
      newRows[index] = {
        ...newRows[index],
        [field]: value
      };
    }
    return newRows;
  });

  // Track unsaved changes
  setUnsavedChanges(prev => {
    const newMap = new Map(prev);
    let itemChanges = newMap.get(id) || {};
    newMap.set(id, { ...itemChanges, [field]: value });
    return newMap;
  });

  setShowSaveBar(true);
}, []);

const handleSaveChanges = async () => {
  setLoading(true);
  try {
    const updates = Array.from(unsavedChanges.entries()).map(([id, data]) => ({
      estimateId: id,
      dataToUpdate: data
    }));

    const response = await axios({
      method: 'post',
      url: `${props.url}/api/proposal/modifyOpenEstimates`,
      data: updates.length === 1 ? updates[0] : { updates }
    });

    if (response.data) {
      // const updatedData = Array.isArray(response.data) ? response.data : [response.data];
      
      // // Update the filtered rows with new data
      // setFilteredRows(prev => 
      //   prev.map(row => {
      //     const updated = updatedData.find(u => u._id === row._id);
      //     return updated || row;
      //   })
      // );
      
      // // Update the main rows array
      // setRows(prev => 
      //   prev.map(row => {
      //     const updated = updatedData.find(u => u._id === row._id);
      //     return updated || row;
      //   })
      // );

      // Clear unsaved changes
      setUnsavedChanges(new Map());
      setShowSaveBar(false);
      
      // Notify parent
      // props.updateParent(response.data);
    }
  } catch (error) {
    console.error('Error saving changes:', error);
    // Could add error notification here
  } finally {
    setLoading(false);
  }
};

const handleCancelChanges = () => {
  // Revert all changes
  setFilteredRows(prev => 
    prev.map(row => {
      const originalRow = rows.find(r => r._id === row._id);
      return originalRow || row;
    })
  );
  setUnsavedChanges(new Map());
  setShowSaveBar(false);
};

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClickCheckbox = React.useCallback((event, _id) => {
    event.stopPropagation(); // Add this to prevent event bubbling
    
    setSelected(prevSelected => {
      const selectedIndex = prevSelected.indexOf(_id);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = [...prevSelected, _id];
      } else if (selectedIndex === 0) {
        newSelected = prevSelected.slice(1);
      } else if (selectedIndex === prevSelected.length - 1) {
        newSelected = prevSelected.slice(0, -1);
      } else if (selectedIndex > 0) {
        newSelected = [
          ...prevSelected.slice(0, selectedIndex),
          ...prevSelected.slice(selectedIndex + 1)
        ];
      }
  
      return newSelected;
    });
  }, []);
  
  // 3. Update the handleSelectAllClick to be more responsive:
  const handleSelectAllClick = React.useCallback((event) => {
    if (event.target.checked) {
      const newSelecteds = filteredRows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(n => n._id);
        
      setSelected(prevSelected => 
        prevSelected.length === newSelecteds.length ? [] : newSelecteds
      );
    } else {
      setSelected([]);
    }
  }, [filteredRows, page, rowsPerPage]);

    
  const modifyOurList = (newList) => {
  // console.log('LIst has updated', newList)
    setRows(newList)
    if (searchValue) {
      filterOut(rows, searchValue)
    } else {
      setFilteredRows(newList)
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleChange = (name) => (event) => {
  // console.log('Searching: ', event.target.value)
    setSearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }
  
  const filterOut = (r, searchText) => {
    let string = searchText
    let newArr = []
    r.filter(o => {
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log('String', str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
  // console.log('Search results:', newArr)
    setFilteredRows(newArr)

  }



  const handleModificationResponse = (data) => {
  // console.log('Modified:', data)
    setSelected([])
    props.updateParent(data)
  }

  const handleUpdateServer = async (estimateId, dataToUpdate) => {
    // console.log('Update Server', dataToUpdate)
    setLoading(true);
    try {
      const response = await axios({
        method: 'post',
        url: `${props.url}/api/proposal/modifyOpenEstimates`,
        data: {
          estimateIds: [estimateId],
          dataToUpdate
        }
      });
      // console.log('Response ', response.data)
      if (response.data) {
        props.notification({
          type: 'success',
          title: `Updated Our Estimates`,
          message: `Updates are Complete`
        })
        // Update local state
        const updatedData = Array.isArray(response.data) ? response.data : [response.data];
        // console.log('Updated data..', updatedData)
        // Update the filtered rows with new data
        setFilteredRows(prev => 
          prev.map(row => {
            // console.log(row)
            // console.log(row._id)
            const updated = updatedData.find(u => u._id === row._id);
            // console.log('updated??', updated)
            return updated || row;
          })
        );
        
        // Update the main rows array as well
        setRows(prev => 
          prev.map(row => {
            const updated = updatedData.find(u => u._id === row._id);
            return updated || row;
          })
        );

        // Notify parent component
        // props.updateParent(response.data);
  
        // Close dialog and clear selection
        setShowBulkEdit(false);
        setSelected([]);
        
        // Notify parent
        // props.updateParent(response.data);
      }
    } catch (error) {
      console.error('Error updating estimates 743:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleBulkResponseOnStatusUpdates = (estimateIds, dataToUpdate) => {
    console.log('Set this', dataToUpdate)
    console.log('on these', estimateIds)
    handleBulkUpdate(dataToUpdate)
  }
  
  const totalRevenue = filteredRows.reduce(function (acc, obj) { return acc + (obj.amount ? parseFloat(obj.amount) : 0); }, 0);
  
  return (
    <div >
      {/* <Paper className={classes.paper}> */}
        <h3>Open Estimates</h3>
        <Grid container padding={2}>
          <Grid item lg={3} sm={6} xs={6} md={6}>
            <TextField
              name="search" 
              value={ searchValue } 
              onChange={ handleChange('search') }
              label="Search Proposals"
              type="search"
              variant="outlined"
              margin="normal"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
          />
          <div style={{ marginLeft: '16px' }} >
            {filteredRows.length} / {props.list.length} Estimates
          </div>
          <div style={{ marginLeft: '16px' }} >
            Revenue of These {filteredRows.length} Estimates: {currencyFormat(totalRevenue)}
          </div>
          </Grid>
          <Grid item lg={4} sm={6} xs={6} md={6}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', marginLeft: '20px' }}>
            {selected.length > 0 ? (
              <div className={classes.actionContainer}>
                <div>{selected.length} Proposals Selected</div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowBulkEdit(true)}
                >
                  Bulk Edit
                </Button>
                <div className={classes.estimateAction}>
                  <EstimateAction 
                    item={selected} 
                    url={props.url} 
                    setLoading={setLoading} 
                    updateParent={handleModificationResponse} 
                    sendToBackEnd={handleBulkResponseOnStatusUpdates}
                    isBulk={true}
                  />
                </div>
              </div>
            ) : null}
            </div>
          </Grid>
          
        </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
             <TableContainer className={classes.tableWrapper}>
                <Table stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
              />
                  <TableBody>
                    {displayData.map((item, index) => {
                      const isItemSelected = selected.indexOf(item._id) !== -1;
                      return (
                        <MemoizedTableRow
                          key={item._id}
                          item={item}
                          index={index}
                          url={props.url}
                          selected={selected}
                          isItemSelected={isItemSelected}
                          handleClickCheckbox={handleClickCheckbox}
                          handleFieldChange={handleFieldChange}
                          classes={classes}
                          handleModificationResponse={handleModificationResponse}
                          handleSendToServer={handleUpdateServer}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              
        </div>
          <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100, 500]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                // onChangePage={handleChangePage}
                // onPageChange={handleChangePage}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />  
  
    {showSaveBar && (
      <SaveChangesBar
        onSave={handleSaveChanges}
        onCancel={handleCancelChanges}
      />
    )}
    {showBulkEdit && (
        <BulkEditDialog
          open={showBulkEdit}
          onClose={() => setShowBulkEdit(false)}
          selectedIds={selected}
          onSave={handleBulkUpdate}
          loading={loading}
        />
      )}
    </div>
  )
}


const EstimateAction = ({ item, url, updateParent, disabled, sendToBackEnd, isBulk }) => {
  // console.log('Estimate Action:', props)
  const options = ['Change Status', 'Duplicate', 'Archive', 'Lost', 'Accept'];
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [showStatusModal, setShowStatusModal] = React.useState(false)
  // const [loading, setLoading] = React.useState(false)

  const updateEstimate = (dataToUpdate) => {
    console.log('Update the Estimate', dataToUpdate)
    console.log('Item/Items', item)
    console.log('Is Bulk', isBulk)
    if (isBulk) {
      sendToBackEnd(item, dataToUpdate)
    } else {
      sendToBackEnd(item._id, dataToUpdate)
    }
  }

  const handleClick = () => {
    // console.info(`You clicked ${options[selectedIndex]}`);
    if (options[selectedIndex] === 'Change Status') {
      setShowStatusModal(true)
    } else {
      updateEstimate({ status: options[selectedIndex] })
    }
    // if (options[selectedIndex] === 'Archive') {
    //   updateEstimate('archive')
    // }
    // if (options[selectedIndex] === 'Closed Lost') {
    //   updateEstimate('lost')
    // }
    // if (options[selectedIndex] === 'Accept') {
    //   updateEstimate('accept')
    // }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleCloseStatusModal = () => {
    setShowStatusModal(false)
  }

  const handleUpdateStatus = (newStatus) => {
    setShowStatusModal(false)
    updateEstimate(newStatus)
  }
  return (
    <div>
      <StatusModal open={showStatusModal} item={item} closeModal={handleCloseStatusModal} updateParent={handleUpdateStatus} />
    <ButtonGroup style={{ maxHeight: 40, fontSize: '0.6em' }} disabled={disabled} variant="contained" color="primary" ref={anchorRef} aria-label="split button">
      <Button onClick={handleClick} >{options[selectedIndex]}</Button>
      <Button
        color="primary"
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        <ArrowDropDownIcon />
      </Button>
    </ButtonGroup>
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{ zIndex: 3 }}  >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                {options.map((option, index) => (
                  <MenuItem
                    key={option}
                    // disabled={index === 2}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
    </div>
  )
}

const MemoizedTableRow = React.memo(({ 
  item, 
  index, 
  isItemSelected, 
  handleClickCheckbox, 
  handleFieldChange,
  classes,
  handleModificationResponse,
  handleSendToServer,
  selected,
  url
}) => {
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    // Convert to YYYY-MM-DD format
    return moment(dateString).add(7, 'hours').format('YYYY-MM-DD');
  };

  // Local state for input values
  const [localConfidence, setLocalConfidence] = React.useState(item.confidence || '');
  const [localDuration, setLocalDuration] = React.useState(item.duration || '');
  const [localDate, setLocalDate] = React.useState(item.expectedCloseDate || '');
  // console.log('Item:', item)
  // Update local state when props change
  React.useEffect(() => {
    setLocalConfidence(item.confidence || '');
    setLocalDuration(item.duration || '');
    setLocalDate(formatDateForInput(item.expectedCloseDate));
  }, [item.confidence, item.duration, item.expectedCloseDate]);

  // Simplified handlers without debouncing
  const handleConfidenceInput = React.useCallback((e) => {
    const value = e.target.value;
    if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
      setLocalConfidence(value);
      handleFieldChange(item._id, 'confidence', value, index);
    }
  }, [handleFieldChange, item._id, index]);

  const handleDurationInput = React.useCallback((e) => {
    const value = e.target.value;
    if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 120)) {
      setLocalDuration(value);
      handleFieldChange(item._id, 'duration', value, index);
    }
  }, [handleFieldChange, item._id, index]);

  const handleDateInput = React.useCallback((e) => {
    const value = e.target.value;
    setLocalDate(value);
    handleFieldChange(item._id, 'expectedCloseDate', value, index);
  }, [handleFieldChange, item._id, index]);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
         <TableCell padding="checkbox">
         <Checkbox
            checked={isItemSelected}
            onClick={(event) => handleClickCheckbox(event, item._id)}
            onChange={() => {}} // Add empty onChange to suppress warning
            inputProps={{ 'aria-labelledby': `${item.name}` }}
            style={{ cursor: 'pointer' }} // Optional: make cursor more obvious
          />
      </TableCell>
      
      <TableCell>
        <Typography variant="body1">{item.name} (#{item.number})</Typography>
        <Typography variant="caption" color="textSecondary">
         
        </Typography>
        <Typography variant="caption" color="textSecondary">Client:  {item.clientName}</Typography>
        <div><Typography variant="caption" color="textSecondary">{item.projectName ? `Project: ${item.projectName}` : ''}</Typography></div>
        {item.contractor && <div><Typography variant="caption" className={classes.secondaryText}>
                    {item.contractor ? `Contractor` : ''}
                  </Typography></div>
        }
      </TableCell>
      
      <MemoizedInfoCell item={item} />
      <TableCell>
        <div className="text-sm">
          {moment(item.created_at).format('MM/DD/YY')}
          <div className="text-xs text-gray-600">{item.created_by}</div>
        </div>
      </TableCell>
            
      <TableCell>
        <div className="text-sm">
          {moment(item.updated_at).format('MM/DD/YY')}
          <div className="text-xs text-gray-600">{item.updated_by}</div>
        </div>
      </TableCell>
      
      <TableCell>
        <span className="font-medium">{currencyFormat(item.amount)}</span>
      </TableCell>
      <TableCell>
        <TextField 
          type="number"
          variant="outlined"
          size="small"
          onFocus={handleFocus}
          value={localConfidence}
          onChange={handleConfidenceInput}
          onBlur={() => {
            if (localConfidence !== item.confidence) {
              handleFieldChange(item._id, 'confidence', localConfidence, index);
            }
          }}
          inputProps={{
            min: 0,
            max: 100,
            step: 1
          }}
          style={{ width: 100 }}
        />
      </TableCell>
      
      <TableCell>
        <TextField 
          type="date"
          variant="outlined"
          size="small"
          value={localDate}
          onChange={handleDateInput}
          onBlur={() => {
            if (localDate !== formatDateForInput(item.expectedCloseDate)) {
              handleFieldChange(item._id, 'expectedCloseDate', localDate, index);
            }
          }}
          style={{ width: 150 }}
        />
      </TableCell>
      
      <TableCell>
        <TextField 
          type="number"
          variant="outlined"
          size="small"
          value={localDuration}
          onFocus={handleFocus}
          onChange={handleDurationInput}
          onBlur={() => {
            if (localDuration !== item.duration) {
              handleFieldChange(item._id, 'duration', localDuration, index);
            }
          }}
          inputProps={{
            min: 0,
            max: 120,
            step: 1
          }}
          style={{ width: 100 }}
        />
      </TableCell>
      <TableCell>
        <EstimateAction
          disabled={selected.length > 0}
          item={item} 
          url={url} 
          updateParent={handleModificationResponse} 
          sendToBackEnd={handleSendToServer}
          isBulk={false}
        />
      </TableCell>
    </TableRow>
  );
}, (prevProps, nextProps) => {
  // Improved memo comparison
  return (
    prevProps.isItemSelected === nextProps.isItemSelected &&
    prevProps.item.confidence === nextProps.item.confidence &&
    prevProps.item.expectedCloseDate === nextProps.item.expectedCloseDate &&
    prevProps.item.duration === nextProps.item.duration &&
    prevProps.index === nextProps.index &&
    prevProps.handleFieldChange === nextProps.handleFieldChange &&
    prevProps.selected.length === nextProps.selected.length
  );
});

const MemoizedInfoCell = React.memo(({ item }) => {
  // console.log('1123 ', item)
  const classes = useStyles();
  return (
    <TableCell>
      <div className={classes.infoContainer}>
        <div className={classes.logoContainer}>
            <img 
              alt={`${item.entity} Logo`}
              src={`${process.env.PUBLIC_URL}/${item.entity}_Logo.png`}
              className={classes.logo}
            />
           
          </div>
          <div className={classes.clientInfo}>
            {
              item.salesRep && (
                <>
                  <Typography variant="body2" className={classes.secondaryText}>
                    {item.salesRep ? `Sales Rep: ${item.salesRep}` : '' }
                  </Typography>
                </>
              )
            }
          </div>
          <div>

           
            <Chip
              label={item.status}
              size="small"
              className={classes.statusChip}
              color={
                item.status === 'Open' ? 'primary' :
                item.status === 'In Progress' ? 'secondary' :
                'default'
              }
            />
          </div>
      </div>
    </TableCell>
  );
});

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ListOfOpenEstimates)

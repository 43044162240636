import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ProtectedButton from '../../actions/protectedButtons'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ApprovalActions from './ApprovalActions'
import POUploads from './POUploads'
import RestoreIcon from '@material-ui/icons/Restore';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ConfirmReceiptModal from './ConfirmReceiptModal'
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const defaultValues = {
  status: 'New',
  projectName: '',
  poNumber: null,
  amount: null,
  poType: null,
  showUploads: false
}

const Divisions = [
  'Landscape',
  'Hardscape',
  'Maintenance',
  'Pools',
  'Concrete',
  'Metal Fabrication',
  'Trucking',
  'Crane',
  'Specialty',
  'Tech Service',
  'Mechanic',
  'New River Nursery',
  'Carefree Nursery',
  'Concrete Yard',
  'ADOT',
  'Maricopa County',
  'Commercial',
  'Office',
  'General Repair'
]

const EditPOModal = (props) => {
// console.log('EditPOModal Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState({})
  const [clients, setClients] = React.useState([])
  const [vendor, setVendor] = React.useState({})
  const [vendors, setVendors] = React.useState([])
  const [state, setState] = React.useState(defaultValues)
  const [requestor, setRequestor] = React.useState({})
  const [clientWorkorders, setClientWorkorders] = React.useState([])
  const [workorder, setWorkorder] = React.useState({})
  const [po, setPO] = React.useState({})
  const [upload, setUpload] = React.useState({})
  const [projects, setProjects] = React.useState([])
  const [project, setProject] = React.useState({})
  const [showConfirmReceiptModal, setShowConfirmReceiptModal] = React.useState(false)

  const inputRef = React.useRef({
    invoiceNumber: '',
    amount: 0
   })
   const [pdf, setPDF] = React.useState(null)

  React.useEffect(() => {
  // console.log('edit po modal use effect')
    let isMounted = true
    const fetchClients = async () => {
      // console.log('Fetch clients')
      setLoading(true)
      // ?byEntity=610a290f45ef7427f94b2e78
      let url = `${props.url}/api/customer/clients`
      axios.get(url).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          // console.log('Set Clients: ', response.data)
          if (isMounted) {
            setClients(response.data)
            if (props.po.client) {
              setState({...state, poType: 'Client Project/Job' })
              if(props.po.client._id) {
                setClient(props.po.client)
              } else {
              // console.log('Find client in these ', response.data)
                let index = response.data.findIndex(item => (item._id === props.po.client))
                if (index > -1) {
                  setClient(response.data[index])
                }
              }
            }
          }
        }
      })
    }

    const fetchVendors = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/company/getVendors`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) {
            setVendors(response.data)
            if (props.po.vendor) {
              // console.log('Set our Vendor', props.po)
              if (props.po.vendor._id) {
                // console.log('set th evendor', props.po.vendor)
                setVendor(props.po.vendor)
              } else {
                // Find it by vendors
                // console.log('In this list of vendors...', response.data)
                let index = response.data.findIndex(item => (item._id === props.po.vendor))
                if (index > -1) {
                  setVendor(response.data[index])
                }
              }
            }
          }
        }
      })
    }
    // if (props.clients && props.clients.length) {
    //   setClients(props.clients)
    // }
    if (props.open) {
      fetchClients()
      fetchVendors()
      setPO(props.po)

      if (props.po.requestor) {
      // console.log('Set the requestor!!', props.po.requestor)
        if (props.po.requestor._id) {
          setRequestor(props.po.requestor)
          inputRef.current.requestor = props.po.requestor
        } else {
        // console.log('Find our requestreo!!', props.users)
          let index = props.users.findIndex(item => (item._id === props.po.requestor))
        // console.log('Requestor INDEX Index', index)
          
          // console.log
          if (index > -1) {
            let user = props.users[index]
          // console.log('USER:', user)
            setRequestor(user)
            inputRef.current.requestor = user
          }
        }
      }

      // if (props.po.poType) 
      // Set Requestor to user
      // console.log('set requestor', props)
      // let index = props.employees.findIndex(item => (item.user === props.user._id))
      // // console.log('INDEX: ', index)
      // if (index > -1) {
      //   setRequestor(props.employees[index])
      //   inputRef.current.requestor = props.employees[index]
      // }
    }

    return () => {
      isMounted = false
    }
  }, [props.open])

  
  React.useEffect(() => {
    let isMounted = true
    async function fetchWos(id) {
      // console.log('Get id:', id)
      setLoading(true)
      axios.get(`${props.url}/api/jobs/getClientWorkorders?id=${id}`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          // console.log('Res', response.data)
          if (isMounted) {
            setClientWorkorders(response.data)
            if (props.po && props.po.workorder) {
              // console.log('Set our workorder!!!', props.po.workorder)
              if (props.po.workorder._id) {
                setWorkorder(props.po.workorder)
              } else {
                let index = response.data.findIndex(item => (item._id === props.po.workorder))
                if (index > -1) {
                  setWorkorder(response.data[index])
                }
              }
            }
          }
        }
      })
    }

    async function fetchProjects(id) {
      // console.log('Get id:', id)
      setLoading(true)
      axios.get(`${props.url}/api/projects/clientProjects?id=${id}`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
        // console.log('Client Projects:', response.data)
          if (isMounted) {
            setProjects(response.data)
            if (props.po && props.po.project) {
            // console.log('Set our PROJECT!!!', props.po.project)
              if (props.po.project._id) {
                setProject(props.po.project)
              } else {
              // console.log('Find the project', props.po.project)
                let index = response.data.findIndex(item => (item._id === props.po.project))
              // console.log('inDex...', index)
                if (index > -1) {
                  setProject(response.data[index])
                }
              }
            }
          }
        }
      })
    }
    
    if (client && client._id) {
      
      setTimeout(() => {
        // fetchWos(client._id)
        fetchProjects(client._id)
      }, 10)
    }

    return () => {
      isMounted = false
    }
  }, [client])


  const handleSave = () => {
    // console.log(inputRef)
    setLoading(true)
    // setClient({})
    let ourPO = {...po}
    ourPO.vendor = vendor._id
    ourPO.vendorName = vendor.name
    ourPO.requestor = requestor._id
    ourPO.requestorName =  requestor.name //  + ' ' + requestor.lastName
    if (client) {
      ourPO.client = client._id
      ourPO.clientName = client.displayName
    }
    if (workorder) ourPO.workorder = workorder._id
    if (po && po.created_by) ourPO.created_by = po.created_by
    if (po && po.created_by && po.created_by._id) ourPO.created_by = po.created_by._id
    if (project && project._id) {
      ourPO.project = project._id
      ourPO.projectName = project.name
    }
    // console.log('PO TO SAVE:L ', ourPO)
    // setState({...state, status: 'New', projectName: ''})
    axios({
      method: 'post',
      url:  `${props.url}/api/purchaseOrders/updatePO`,
      data: ourPO
    }).then(response => {
      console.log(response.data)
      setLoading(false)
      if (response.data && response.data._id) {
        props.notification({
          type: 'success',
          title: `PO #${response.data.poNumber} Updated`,
          message: 'You Updated a Purchase Order - Well Done!'
        })
        handleClose(response.data)
        if (response.data && response.data.po && response.data.po._id) {
          inputRef.current._id = response.data.po._id
          inputRef.current.poNumber = response.data.poNumber
          setState({...state, poNumber: response.data.poNumber})

        // props.updateParent(response.data)
          
        }
        // props.closeModal()
      }

    })
  }
  const updateCustomer = (e, customer) => {
    if (customer && customer._id) {
      setClient(customer)
      inputRef.current.client = customer
    }
  }

  const updateRequestor = (e, customer) => {
    if (customer && customer._id) {
      setRequestor(customer)
      inputRef.current.requestor = customer
      inputRef.current.requestorName = customer.firstName + ' ' + customer.lastName
    }
  }
  
  const updateIsSubcontractor = () => {
    // inputRef.current.isSubcontractor = !state.isSubcontractor
    setPO(prevPO => ({...prevPO, isSubcontractor: !po.isSubcontractor }))
  }
  
  const updateVendor = (e, newVendor) => {
    if (newVendor && newVendor._id) {
      setVendor(newVendor)
      inputRef.current.vendorName = newVendor.name
      inputRef.current.vendor = newVendor
    }
  }

  const updateWorkorder = (e, wo) => {
    // console.log('update workorder:', wo)
    if (wo && wo._id) {
      setWorkorder(wo)
      // fetchServiceVisits(wo._id)
      inputRef.current.workorder = wo
      setState({...state, workorder: wo })
    }
  }

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'customername') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          updateCustomer(e, newArr[0])
        }
      }
      if (e.target.id === 'vendorname') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        vendors.filter(o => {
          let str = o['name'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setVendor(newArr[0])
          updateVendor(null, newArr[0])
        }
      }
      if (e.target.id === 'workorder') {
        // console.log('Set Workorder', e.target.value)
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        clientWorkorders.filter(option => {
          let retValue = ''
          if (option && option.number) retValue = `WO #${option.number} -- ${option.serviceName ? option.serviceName : ''} - ${option.jobName ? option.jobName : '' } - ${option.jobNumber ? option.jobNumber : ''} Scheduled: ${moment(option.scheduledDate).format('MM/DD/YYYY')}`
          // return retValue
          // console.log('Search', retValue)
          // let str = `${option.serviceName ? option.serviceName : ''} - ${option.jobName ? option.jobName : '' } - ${option.jobNumber ? option.jobNumber : ''}`.toString().toLowerCase()
          // o['displayName'].toString().toLowerCase()
          if (retValue.includes(string.toLowerCase())) {
            return newArr.push(option)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
        // console.log(newArr[0])
          // setWorkorder(newArr[0])
          updateWorkorder(e, newArr[0])
        }
      }
      if (e.target.id === 'requestername') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.employees.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          updateRequestor(null, newArr[0])
        }
      }
      
    }
  }

  const handleClose = (data) => {
    inputRef.current = {}
    setState(defaultValues)
    setWorkorder({})
    setClient({})
    setProject({})
    setVendor({})
    setPDF(null)
    setUpload(null)
    setRequestor(null)
    props.closeModal(data)
  }
  
  const updateStatus = (e, status) => {
    inputRef.current.status = status
  }
  const updateRef = (e) => {
    inputRef.current[e.target.id] = e.target.value
    setState({...state, [e.target.id]: e.target.value })
  }

  const handleInvoiceTypeChange = (e) => {
    // setInvoiceType(e.target.value)
    inputRef.current.poType = e.target.value
    setState({...state, poType: e.target.value })
    setPO({...po, poType: e.target.value })
  }

  const autoUpdatePO = (e) => {
    inputRef.current[e.target.id] = e.target.value
    setState({...state, [e.target.id]: e.target.value })
    setLoading(true)
    // setClient({})
    // console.log('Update PO: ', inputRef.current)
    // setState({...state, status: 'New', projectName: ''})
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/autoUpdatePO`,
      data: inputRef.current
    }).then(response => {
      setLoading(false)

      // if (response.data && response.data.poNumber) {
      //   props.notification({
      //     type: 'success',
      //     title: `PO #${response.data.poNumber} Generated`,
      //     message: 'You Created a Purchase Order - Well Done!'
      //   })
      //   setState({...state, poNumber: response.data.poNumber})
      //   // props.updateParent(response.data)
     
      //   // props.closeModal()
      // }

    })
  }

  const fetchPDF = async (url, callback) => {
  // console.log('FETCH: ', url)
    let cleanURL = ''
    if (url && url.includes('https')) {
      cleanURL = url
    } else {
      cleanURL = `https://${url}`
    }
    // console.log('CLEAN: ', cleanURL)
    await axios.get(cleanURL, {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/octet-stream',
        'Accept': 'application/pdf, application-octet-stream'
      },
      responseType: 'blob'
    }).then((response) => {
       
        let newBlob = new Blob([response.data], {type: "application/pdf"})
        const data = window.URL.createObjectURL(newBlob);
        // setPDF(data)
        return callback(data)
      })
  }

  const handleViewPO = () => {
    // console.log(po)
    setState({...state, showUploads: !state.showUploads })
    // if (pdf) {
    //   setPDF(null)
    // } else {
    //   setLoading(true)
    //   axios.get(`${props.url}/api/purchaseOrders/reconciledDetails?id=${po._id}`).then((response, status, data) => {
    //     setLoading(false)
    //     if (response && response.data) {
    //     // console.log('Vendor invoice details:', response.data)
    //       // This hsould be the UPLOAD document that includes a URL
    //       if (response.data && response.data.url) {
    //         setUpload(response.data)
    //         // window.open(response.data.url, '_blank', 'noopener,noreferrer')
    //         // setPDF(response.)
    //         // fetchPDF(response.data.url)
    //       }
    //     }
    //   })
    // }
  }

  const handleApprovalUpdate = (data) => {
    setPO(data)
  }

  const updateProject = (e, newProj) => {
    setProject(newProj)
  // console.log('Update project', newProj)
  }
  const handleUnReconcile = () => {
    let msg = `Are you sure you wish to un-reconcile this? This will detach any uploads associated with this PO and mark this PO back to Approved. Any uploads currently associated with this PO will be put back in the Bulk Uploads`
    if (window.confirm(msg)) {
      setLoading(true)
      let obj = {
        poId: props.po._id,
        vendorInvoice: props.po.vendorInvoice
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/purchaseOrders/unreconcile`,
        data: obj
      }).then(response => {
        console.log(response.data)
        setLoading(false)
        if (response.data && response.data._id) {
          props.notification({
            type: 'success',
            title: `PO #${response.data.poNumber} Is Ready To Reconcile`,
            message: 'You Updated a Purchase Order - Well Done!'
          })
          handleClose(response.data)
        }
      })
    }
  }

  const handleDivisionChange = (e) => {
    // inputRef.current.division = e.target.value
    setPO(prevPO => ({
      ...prevPO,
      division: e.target.value
    }))
  }

  const handleConfirmReceipt = () => {
    console.log('Gotta receive these products!')
    setShowConfirmReceiptModal(true)
  }

  const handleCloseConfirmReceiptModal= () => {
    console.log('close the modal...')
    setShowConfirmReceiptModal(false)
  }
  const handleFocus = (event) => event.target.select();

  console.log('PO::', po)

  return (
    <div>
      <ConfirmReceiptModal open={showConfirmReceiptModal} purchaseOrder={po} closeModal={handleCloseConfirmReceiptModal} />
      <Dialog open={props.open} onClose={handleClose} maxWidth='lg' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit Purchase Order</DialogTitle>
          <DialogContent >
          { (props.po.status === 'Reconciled' || props.po.upload || props.po.numberOfUploads) ? 
            <IconButton onClick={handleViewPO} edge='end' alt="View" aria-label="View PO PDF" style={{ marginLeft: '90%' }}> 
              <VisibilityIcon />
            </IconButton>
            : null 
          }
          
            <DialogContentText>
              Purchase Order Details
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {
                  state.showUploads ? <POUploads po={po} pdf={pdf} /> : null
                }
              </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button onClick={handleConfirmReceipt} variant="contained" color="primary" startIcon={<LocalShippingIcon />}>
                      Confirm Receipt
                    </Button>
                  </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <DialogContentText color="primary" style={{ fontSize: '28px', textAlign: 'center' }}>
                            PO # { po.number || '' } ({po.status})
                          </DialogContentText>
                        </Grid>
                        {
                          po.status === 'Pending Approval' ? (
                          <ProtectedButton type="Approve POs" kind="update" perms={props.perms}>
                           
                              <ApprovalActions po={po} updateParent={handleApprovalUpdate} />
                           
                         </ProtectedButton> 
                          ) : null
                         
                        }
                       
                         {
                          po.status === 'Reconciled' ? (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <ProtectedButton type="Approve POs" kind="update" perms={props.perms}>
                              
                                <Button onClick={handleUnReconcile} variant="contained" color="primary" startIcon={<RestoreIcon />}>
                                  Un-Reconcile
                                </Button>
                              
                              </ProtectedButton> 
                              { (po.reconciled_at && po.reconciled_by_name) && <div>
                                    Reconciled by {po?.reconciled_by_name} {moment(po?.reconciled_at).format('MM/DD/YY [at] hh:mma')}
                                  </div>
                                } 
                            </Grid>
                          ) : null
                         
                        }
                        
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          {
                            (upload && upload._id) ? <UploadDetails upload={upload} fetchPDF={fetchPDF} /> : null
                          }
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            id="amount"
                            label="PO Amount"
                            // style={{ margin: 8 }}
                            // disabled={props.po.status === 'Reconciled'}
                            // value={inputRef.projectName}
                            value={po.amount}
                            onChange={(e) => setPO({...po, amount: e.target.value })}
                            // onChange={updateRef}
                            // onChange={e=> setState({...state, projectName: e.target.value})}
                            // ref={inputRef['projectName']}
                            placeholder="Purchase Order Amount"
                            helperText="How Much Is This Purchase Order For?"
                            fullWidth
                            // autoFocus
                            onFocus={handleFocus}
                            // onBlur={autoUpdatePO}
                            type="number"
                            // value={inputRef.current['projectName']}
                            margin="normal"
                            variant="outlined"
                            // InputLabelProps={{
                            //   shrink: true,
                            // }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            id="invoiceNumber"
                            label="Invoice Number"
                            // style={{ margin: 8 }}
                            value={po.invoiceNumber}
                            // disabled={props.po.status === 'Reconciled' || loading}
                            onChange={(e) => setPO({...po, invoiceNumber: e.target.value })}
                            // onChange={updateRef}
                            // onChange={e=> setState({...state, projectName: e.target.value})}
                            // ref={inputRef['projectName']}
                            placeholder="Invoice Number"
                            helperText="What is the Associated Invoice Number?"
                            fullWidth
                            type="text"
                            // onBlur={autoUpdatePO}
                            // value={inputRef.current['projectName']}
                            margin="normal"
                            variant="outlined"
                            // InputLabelProps={{
                            //   shrink: true,
                            // }}
                          />
                        </Grid>
                    
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`vendorname`}
                  options={vendors || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.name || ''
                  }}
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  // disabled={props.po.status === 'Reconciled' || loading}
                  onChange={updateVendor}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {vendor}
                  // ref={inputRef.client}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Vendor" helperText="Who Is The Vendor?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl fullWidth >
                  {/* <InputLabel style={{marginLeft: 10}} >Type of Purchase Order</InputLabel> */}
                  <TextField
                    id="poType"
                    value={po.poType}
                    fullWidth
                    label="PO Type"
                    select
                    variant="outlined"
                    // disabled={props.po.status === 'Reconciled' || loading}
                    onChange={handleInvoiceTypeChange}
                    // onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
                  >
                    <MenuItem value={'Client Project/Job'}>Client Project/Job</MenuItem>
                    <MenuItem value={'Yard Stock'}>Yard Stock</MenuItem>
                    <MenuItem value={'Cement Company'}>Cement Company</MenuItem>
                    <MenuItem value={'Tools'}>Tools</MenuItem>
                    <MenuItem value={'Inventory'}>Inventory</MenuItem>
                    <MenuItem value={'Marketing'}>Marketing</MenuItem>
                    <MenuItem value={'Repair'}>Repair</MenuItem>
                    <MenuItem value={'Office'}>Office</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                  </TextField>
                  <FormHelperText>What Kind of PO Is This?</FormHelperText>
                </FormControl>
                {
                  state.poType === 'Client Project/Job' ? (
                    <Autocomplete
                    id={`customername`}
                    options={clients || []}
                    getOptionLabel={(option) => {
                      // console.log('Options: ', option)
                      return option.displayName || ''
                    }}
                    // style={{ width: 200 }}
                    // style={{marginBottom: '20px'}}
                    onChange={updateCustomer}
                    // disabled={props.po.status === 'Reconciled' || loading}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    value = {client}
                    // ref={inputRef.client}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Customer" helperText="Who Is The Client?" onKeyDown={handleTabInteraction} variant="outlined" />}
                  />
                  ) : null
                }
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl fullWidth >
                    <TextField
                      id="division"
                      select
                      fullWidth
                      variant="outlined"
                      label="Division"
                      onChange={handleDivisionChange}
                      value={po.division}
                    >
                      {
                        Divisions.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                          )
                        })
                      }
                    </TextField>
                    <FormHelperText>What Division?</FormHelperText>
                </FormControl>
              </Grid>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`customername`}
                  options={clients || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.displayName || ''
                  }}
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateCustomer}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {client}
                  // ref={inputRef.client}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Customer" helperText="Who Is The Client?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
              </Grid> */}
              <Grid item xs={12} lg={12}>
               {
                 (client && client._id) ? (
                    <Autocomplete
                    id={`project`}
                    options={projects || []}
                    getOptionLabel={(option) => {
                      // console.log('Project Options: ', option)
                      return option.name || ''
                    }}
                    // style={{ width: 200 }}
                    // style={{marginBottom: '20px'}}
                    onChange={updateProject}
                    disabled={loading}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    value = {project}
                    // ref={inputRef.client}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Project" helperText="Is This Associated With a Project?" onKeyDown={handleTabInteraction} variant="outlined" />}
                  />
                  //   <Autocomplete
                  //   id={`workorder`}
                  //   options={clientWorkorders || []}
                  //   // disabled={props.po.status === 'Reconciled' || loading}
                  //   getOptionLabel={(option) => {
                  //     // console.log('Options: ', option)
                  //     let retValue = ''
                  //     if (option && option.number) retValue = `WO #${option.number} -- ${option.serviceName ? option.serviceName : ''} - ${option.jobName ? option.jobName : '' } - ${option.jobNumber ? option.jobNumber : ''} Scheduled: ${moment(option.scheduledDate).format('MM/DD/YYYY')}`
                  //     return retValue 
                  //   }}
                  //   // style={{ width: 200 }}
                  //   style={{marginBottom: '20px'}}
                  //   onChange={updateWorkorder}
                  //   // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  //   value = {workorder}
                  //   // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  //   renderInput={(params) => <TextField {...params} label="Workorder" onKeyDown={handleTabInteraction} variant="outlined" />}
                  // />
                 ) : null
               } 
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`requestername`}
                  options={props.users || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    
                    return option.name || ''
                  }}
                  // disabled={props.po.status === 'Reconciled'}
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateRequestor}
                  // disabled={props.po.status === 'Reconciled' || loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {requestor}
                  // ref={inputRef.client}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Requestor" helperText="Who Is Requesting The Purchase Order?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
              </Grid>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="amount"
                  label="PO Amount"
                  // style={{ margin: 8 }}
                  // value={inputRef.projectName}
                  onChange={updateRef}
                  // onChange={e=> setState({...state, projectName: e.target.value})}
                  // ref={inputRef['projectName']}
                  placeholder="Purchase Order Amount"
                  helperText="How Much Is This Purchase Order For?"
                  fullWidth
                  type="number"
                  // value={inputRef.current['projectName']}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid> */}
               <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl component="fieldset" style={{ marginTop: '16px' }}>
                    <Box display="flex" alignItems="center">
                      
                      <FormControlLabel
                        control={<Checkbox checked={po.isSubcontractor} onChange={updateIsSubcontractor} name="isSubcontractor" />}
                        label=""
                      />
                      <FormLabel component="legend">Is This a Sub-Contractor?</FormLabel>
                    </Box>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="description"
                  label="PO Description"
                  // style={{ margin: 8 }}
                  // ref={inputRef.projectDescription}
                  placeholder="PO Description"
                  helperText="What Is Covered by this PO?"
                  // disabled={props.po.status === 'Reconciled' || loading}
                  fullWidth
                  multiline
                  minRows={3}
                  value={po.description}
                  onChange={(e) => setPO({...po, description: e.target.value })}
                  // onChange={updateRef}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid>
              
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
              Cancel
            </Button> */}
            <ProtectedButton type="Purchase Orders" kind="update" perms={props.perms}>
              <Button color="primary" disabled={loading || (props.po.amount && !po.amount) || (props.po.invoiceNumber && !po.invoiceNumber) || !vendor || !requestor || !po.poType } onClick={handleSave}>
                  {
                    loading ? <CircularProgress /> : 'Save'
                  }
                </Button>
            </ProtectedButton>
            

          </DialogActions>
        </Dialog>
    </div>
  )
}

const UploadDetails = (props) => {
  const [pdf, setPDF] = React.useState(null)

  React.useEffect(() => {
    if (props.upload && props.upload.type && props.upload.type.includes('pdf')) {
    // console.log('Fetch our PDF', props.upload)
      props.fetchPDF(props.upload.url, (resp) => {
        setPDF(resp)
      })
    }
  }, [props.upload])
  let theURL = props.upload.url
  if (theURL && theURL.includes('https')) {
    // All good
  } else {
    theURL = 'https://' + props.upload.url
  }
  return (
    <div>
      {
        (props.upload && props.upload.type && props.upload.type.includes('pdf')) ? 
          <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="900px" /> : 
            (props.upload && props.upload.type && props.upload.type.includes('image')) ?  <img src={theURL} style={{ width: '100%' }} /> : null
      }
      {
        (props.upload && props.upload.type && (props.upload.type.includes('csv') || props.upload.type.includes('office'))) ? <iframe width="100%" height="900px" src={`https://docs.google.com/gview?url=${props.upload.url}&embedded=true`}></iframe> : null
      }
    </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      salesReps: state.salesReps,
      employees: state.employees,
      perms: state.perms,
      users: state.users
    }
  }
  
  export default connect(mapStateToProps)(EditPOModal)